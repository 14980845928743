import "./App.css";
import { Route, useLocation, useNavigate } from "react-router-dom";
import { Routes } from "react-router";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "./screens/Forms/Forms";
import Homepage from "./screens/Homepage/Home";
import ErrorPage from "./screens/Errorpage/Error";
import StillCoveredPage from "./screens/Errorpage/StillCovered";
import "./components/common.css";
import PaymentSuccess from "./screens/Forms/Steps/PaymentSuccess";
import Errorpage from "./screens/Forms/Steps/Errorpage";
import { useEffect, useState } from "react";
import useWindowDimensions from "./utils/useWindowDimension";
import { useDispatch, useSelector } from "react-redux";
import { getAgentDetails, login, updateLoading } from "./store/actions/auth";
import { clearRiskState, clearState } from "./store/actions/products";
import { parseQuery, setParam } from "./utils/utils";

import Loader from "./components/Loader";

console.log = () => {};
console.error = () => {};
console.warn = () => {};

function App() {
  const { width } = useWindowDimensions();

  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const riskAttributes = useSelector((state) => state.products.riskAttributes);
  const currentToken = useSelector((state) => state.auth.currentUserToken);

  function parseQuery(queryString) {
    var query = {};
    var pairs = (
      queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  }

  const [ishomeLoading, setIsHomeLoading] = useState(false);
  const agentCode = useSelector((state) => state.auth.agentCode);

  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    if (
      location.pathname !== "/payment-success" ||
      location.pathname !== "/payment-failure"
    ) {
      setParam(history, currentToken);
    }
  }, [currentToken]);


  useEffect(() => {
    if (
      location.pathname !== "/forms" ||
      location.pathname !== "/" ||
      location.pathname !== "/payment-success"
    ) {
      dispatch(clearRiskState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Loader state form
  const isLoading = useSelector((state) => state.auth.loading);
  
  return (
    <Routes>
      <Route path="/forms" element={<Form />} />
      <Route path="/error-occured" element={<ErrorPage />} />
      <Route path="/still-covered" element={<StillCoveredPage />} />
      <Route path="/" element={<Homepage />} />
      <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route path="/payment-failure" element={<Errorpage />} />
    </Routes>
  );
}

export default App;
