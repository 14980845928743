import React, { useEffect, useState } from "react";

// Form Module css
import style from "./Form.module.css";

// MUI Components
import { Box, Stepper, Step, StepLabel } from "@mui/material";
import classNames from "classnames";

import { useNavigate, useLocation } from "react-router";

// Importing Forms

// Redux Hooks
import { useDispatch, useSelector } from "react-redux";

import { UpdateSteps} from "../../store/actions/products";
import useWindowDimensions from "../../utils/useWindowDimension";
import Step1 from "./Steps/Step-1";
import Step2 from "./Steps/Step-2";
import Step3 from "./Steps/Step-3";
import { parseQuery, setParam } from "../../utils/utils";

const Form = () => {
  // Dynamic width from Screen sizes
  const { width } = useWindowDimensions();

  // Initial steps or presisted if user resume
  const intitalStep = useSelector((state) => state.products.activeStep);
  const currentToken = useSelector((state) => state.auth.currentUserToken);
    const history = useNavigate();
    const location = useLocation();

  // Active steps according to forms
  const [activeStep, setActiveStep] = useState(intitalStep || 0);

  const riskAttributes = useSelector((state) => state?.products?.riskAttributes);

  
  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    if (location.pathname !== "/payment-success") {
      setParam(history, currentToken);
    }
  }, [currentToken]);

  useEffect(() => {
    if(riskAttributes?.length < 1){
      history("/");
    }
  },[riskAttributes])

  // Loader state
  const [isLoading, setIsLoading] = useState(false);

  // Dispatch Hooks
  const dispatch = useDispatch();

  // Function performs Next Step
  const handleNext = () => {
    window.scrollTo(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Function performs previous Step
  const handleBack = (step) => {
    
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    
  };

  // Updating Active steps to the store
  useEffect(() => {
  
      dispatch(UpdateSteps(activeStep));
   
  }, [activeStep, dispatch]);




  document.body.style = 'background: #3C409610;';

  return !isLoading ? (
    <div className="container form-container mt-lg-5 mb-5 p-0">
      <div className="d-flex justify-content-center flex-column">
        {width > 770 ? (
          <>
            {/* <------- HEADER --------> */}
            <h3 className={style.header}>
              Instant Funeral Cover in a few clicks
            </h3>

            {/* <------- STEPPER STARTS --------> */}

            <Box className="mt-5">
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={index} >
                    <StepLabel completed={activeStep === index} >{width > 430 ? label.label : ""}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>

            {/* <------- STEPPER ENDS --------> */}
          </>
        ) : null}

        {/* <------- FORMS STARTS --------> */}

        <div className={classNames("container", style.boxBorder)}>
          {activeStep === 0 && (
            <Step1 handleBack={handleBack} handleNext={handleNext} />
          )}

          {activeStep === 1 && <Step2 handleBack={handleBack} handleNext={handleNext}  />}
          {activeStep === 2 && <Step3 handleBack={handleBack} handleNext={handleNext}  />}
        </div>
        {/* <------- FORMS ENDS --------> */}
      </div>
    </div>
  ) : null;
};

const steps = [
  {
    label: "Select Cover",
    title: "Cyber SME Business",
    sub: "Choose a limit of indemnity between R 250 000 and R10 000 000",
  },
  {
    label: "Complete Personal Details",
    title: "Cyber SME Business",
    sub: "Select a quote",
  },
  {
    label: "Complete details",
    title: "Cyber SME Business",
    sub: "Select a quote",
  },
];

export default Form;
