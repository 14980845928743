import { updateLoading } from "../store/actions/auth";
import { executeCalculator } from "../store/actions/products";
import { store } from "../store/store";
import { ATTRIBUTES, RISKS } from "../utils/constants";
import { useDispatch, useSelector } from "react-redux";

export const calculator = (dispatch, coverOption) => {
  return new Promise((resolve, reject) => {
    var count = 0;
    const reduxState = store.getState();
    const state = store.getState()?.products.riskAttributes;
    
var filterRiskAttributes = (code) => {
  let attrByCode = state.filter((ra) => ra.code === code)[0];
  return attrByCode?.attributes.map((y) => {
    return {
             dataType: y.dataType,
             name: y.name,
             instanceId: y.instanceId,
             value:y.name === ATTRIBUTES.PROTECTION_REQUIREMENT
                        ? coverOption === "Just Me" ? "Individual" : "Family"
                        : y.name === ATTRIBUTES.COVER_LEVEL
                        ? `Option ${i + 1}`
                        : null,
            };
      });
  };

  var filterRiskAttributes1 = (code) => {
    let attrByCode = state.filter((ra) => ra.code === code)[0];
    return attrByCode?.attributes.map((y) => {
      return {
               dataType: y.dataType,
               name: y.name,
               instanceId: y.instanceId,
               value:y.name === ATTRIBUTES.PROTECTION_REQUIREMENT
                          ? coverOption === "Just Me" ? "Individual" : "Family"
                          : y.name === ATTRIBUTES.COVER_LEVEL
                          ? `Option ${i + 1}`
                          : null,
              };
        });
    };

    //Individual if ends--///
    if(coverOption === "Just Me"){
    for (var i = 0; i < 4; i++) {
      dispatch(
        executeCalculator({
          payload: {
            factors: [],
            productTypeReference:
              reduxState?.products?.types?.types?.[0]?.instanceId,
            risks: [
              {
                attributes: filterRiskAttributes(RISKS.QUQ),
                riskReference: state.find((x) => x.code === RISKS.QUQ)
                  ?.instanceId,
                code: state.find((x) => x.code === RISKS.QUQ)?.code,
              },
            ],
          },
          index: i,
          protection: coverOption === "Just Me" ? "Individual" : "Family",
        })
      ).then((res) => {
        count = count + 1;
        if (count === 4) {
          dispatch(updateLoading(false));
          resolve();
        }
      });
    }
  }
  //individual if ends--///

  //family if starts--///
  if(coverOption === "Family"){
    for (var i = 0; i < 4; i++) {
      dispatch(
        executeCalculator({
          payload: {
            factors: [],
            productTypeReference:
              reduxState?.products?.types?.types?.[0]?.instanceId,
            risks: [
              {
                attributes: filterRiskAttributes1(RISKS.QUQ),
                riskReference: state.find((x) => x.code === RISKS.QUQ)
                  ?.instanceId,
                code: state.find((x) => x.code === RISKS.QUQ)?.code,
              },
            ],
          },
          index: i,
          protection: coverOption === "Just Me" ? "Individual" : "Family",
        })
      ).then((res) => {
        count = count + 1;
        if (count === 4) {
          dispatch(updateLoading(false));
          resolve();
        }
      });
    }
  }
  //family if ends--///
  });
};

