import CONSTANTS from '../../utils/constants';
import axios from "axios";
import { toast } from "react-toastify";
import { PRODUCTION_CONSTANTS } from '../../utils/constants';
import { store } from '../store';

//********************************* NOTES **********************************//
// 1. Please refer the TYPE from the according reducers.                    //
// 2. All the actions will be exporting to the components.                  //
// 3. Each action may call API Calls and returning the response to store.   //
//********************************* NOTES **********************************//

export const clearState = () => async (dispatch) => {
  // Dispatching to clear all the values in the store
  dispatch({
    type: "CLEAR_STATE",
  });
};

export const UpdateSteps = (payload) => async (dispatch) => {
  // Dispatching --> update the activeStep in the store
  dispatch({
    type: "UPDATE_STEP",
    payload,
  });
};


export const updateCustomAttributes = (payload) => (dispatch) => {
  dispatch({
    type: payload.type,
    payload: payload.payload
  })
}

export const updatedAttributes = (attributes, code) => (dispatch) => {
  const riskAttributes = store.getState().products.riskAttributes;
  var index = riskAttributes.findIndex((x) => x.code === code);
  var arr = [...riskAttributes];
  arr[index] = {...arr[index], attributes};
  dispatch({
    type: 'UPDATE_ATTRIBUTES',
    payload: arr
  })
}

export const getProductRisks = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
  try {
    // JWT token from the store
    const token = await store.getState().auth.token;

    // Calling the product risk API
      const response = await axios.get(
        `${CONSTANTS.MIDDLEWARE_URL}/products/getProductRisks/${CONSTANTS.PRODUCT_INSTANCE_ID}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token: token
        },
      }
    );
    // Calling the bank details
      const response1 = await axios.get(
        `${CONSTANTS.MIDDLEWARE_URL}/products/getLookupItemsList/${CONSTANTS.BANK_BRANCH_GUID}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token: token
        },
      }
    );
    // Dispatching ----> response data to the store
    // Refer the types withe reducer
    dispatch({ type: "COUNTDOWN", payload: 60});
    dispatch({ type: "PRODUCT_RISKS", payload: response.data.data });
    dispatch({ type: "BANK_OPTIONS", payload: response1.data.data });
    resolve(response.data.data)
  } catch (e) {
    reject(e)
  }
  })
};

export const getProductRisksAttributes =
  (risksInstanceID) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        // product risk from the store
        const risks = await store.getState().products.risks.risks;
  
        // JWT token from the store
        const token = await store.getState().auth.token;
  
        // Calling specific risk attributes 
          const response = await axios.get(
            `${CONSTANTS.MIDDLEWARE_URL}/products/getProductRiskAttributes/${risksInstanceID}`,
          {
            headers: {
              "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
              token: token
            },
          }
        );
  
        // Consolidating and dispatching the values & response to the store
        var risk = risks?.find( 
          (find) => find.instanceId === response.data.data.risk.instanceId
        );
        var obj = { ...risk, attributes: response.data.data.attributes };
  
         // Dispatching ----> response data to the store
        dispatch({ type: "PRODUCT_RISKS_ATTRIBUTES", payload: obj });
        dispatch({ type: "PRESISTED_PRODUCT_RISKS_ATTRIBUTES", payload: obj });
        resolve(obj)
      } catch (e) {
        console.log(e);
      }
    })
  };

export const updateAccordion = (payload) => async (dispatch) => {
  dispatch({
    type: 'ACCORDION',
    payload
  })
}

export const getProductRisksAttributesWithPresist =
  (risksInstanceID) => async (dispatch) => {
    try {
      // JWT token from the store
      const token = await store.getState().auth.token;

      // Calling specific risk attributes 
        return axios.get(
          `${CONSTANTS.MIDDLEWARE_URL}/products/getProductRiskAttributes/${risksInstanceID}`,
        {
          headers: {
            "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
            token,
          },
        }
      ).then((response) => {
        return response;
      })
    } catch (e) {}
  };



export const getProductTypeDetails = () => async (dispatch) => {
  try {
    // JWT token from the store
    const token = await store.getState().auth.token;

    // Calling the product types
      const response = await axios.get(
        `${CONSTANTS.MIDDLEWARE_URL}/products/getProductTypes/${CONSTANTS.PRODUCT_INSTANCE_ID}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token,
        },
      }
    );
     // Dispatching ----> response data to the store
     dispatch({ type: "COUNTDOWN", payload: 70});
    dispatch({ type: "PRODUCT_TYPES", payload: response.data.data });
    dispatch({ type: "COUNTDOWN", payload: 85});
  } catch (err) {}
};

export const setLoading = (payload) => (dispatch) => {
  dispatch({
    type: 'LOADING',
    payload: payload
  })
}

export const executeCalculator = (payload) => async (dispatch) => {
  console.log("payload.protection -->", payload.protection);
  return new Promise(async (resolve, reject) => {
    try {
      // JWT token from the store
      const token = await store.getState().auth.token;
  
      // Calling the execute calculator
        const response = await axios.post(
          `${CONSTANTS.MIDDLEWARE_URL}/calculator`,
        payload.payload,
        {
          headers: {
            "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
            token,
          },
        }
      );
      if(payload.protection === 'Individual'){
        console.log("Individual--->", payload.protection);
        console.log('Individual response-->', response)
        resolve(response);
        const individual = store.getState().products?.protectionIndividual;
          var arr = [...individual];
          //  console.log("arr-->", arr);
          // arr[payload.index] = response.data.productOptions[1]
          arr[payload.index] = response.data.data?.productOptions[1]
          console.log("Individual arr-->", arr);
          dispatch({
            type: 'PROTECTION_INDIVIDUAL',
            payload: arr
          })
      }else if(payload.protection === 'Family'){
        console.log("Family--->", payload.protection);
        resolve(response);
        const family = store.getState().products?.protectionFamily;
          var arr =  [...family];
          arr[payload.index] = response.data.data.productOptions[0]
          dispatch({
            type: 'PROTECTION_FAMILY',
            payload: arr
          })
          console.log("Family arr-->", arr);
          // resolve(response);
      }
  
    } catch (err) {
      console.log(err);
      throw err;
    }
  })
};

export const updateValues = (payload) => async (dispatch) => {
  // Risk attributes from the store
  const riskAttributes = await store.getState().products.riskAttributes;
  var arr = [...riskAttributes];
  arr[0] = {
    ...arr[0],
    attributes: payload.attributes,
    startDate: payload.startDate,
  };
  // Dispatching ----> data to the store
  dispatch({ type: "UPDATE_ATTRIBUTE_1", payload: arr });
};

export const updateIEDAttributes = (payload) => async (dispatch) => {
  // Dispatching ----> data to the store
  dispatch({ type: "UPDATE_ATTRIBUTE_1", payload });
};

export const updateStepValue = (payload) => async (dispatch) => {
  // Risk attributes from the store
  const riskAttributes = await store.getState().products.riskAttributes;
  const elementIndex = riskAttributes?.findIndex(
    (ele) => ele.code === payload.code
  );
  var arr = [...riskAttributes];
  arr[elementIndex] = { ...arr[elementIndex], attributes: payload.attributes };
  // Dispatching ---->  data to the store
  dispatch({ type: "UPDATE_ATTRIBUTE_1", payload: arr });
};


export const CreatePolicy = (payload) => async (dispatch) => {
  const token = await store.getState().auth.token;
  return new Promise( async(resolve, reject) => {
    try {
        const response = await axios.post(
          `${CONSTANTS.MIDDLEWARE_URL}/policy/createPolicySale`,
        payload,
        {
          headers: {
            "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
            token,
          },
        }
      );
      dispatch({
        type: 'POLICY',
        payload: response.data.data
      })
      resolve(response);
     } catch (err) {
       reject(err)
     }
  })
};

export const selectProduct = (payload) => async (dispatch) => {
  // Dispatching ---->  payload to the store
  dispatch({ type: "SELECT_PRODUCT", payload });
};

export const updateSelectedOption = (payload) => (dispatch) => {
  // Dispatching ---->  payload to the store
  dispatch({
    type: "SELECTED_OPTION",
    payload,
  });
};

export const updateCompanyDetails = (payload) => (dispatch) =>
  [
     // Dispatching ---->  payload to the store
    dispatch({
      type: "UPDATE_COMPANY_DETAILS",
      payload,
    }),
  ];

export const updateTryCount = (payload) => (dispatch) => {
  // Dispatching ---->  payload to the store
  dispatch({
    type: "UPDATE_TRY_COUNT",
    payload,
  });
};

export const issuePolicy = (payload) => async (dispatch) => {
  // JWT token from the store
  const token = await store.getState().auth.token;

  // Calling the issue policy sale API
    const response = await axios.post(
      `${CONSTANTS.MIDDLEWARE_URL}/policy/issuePolicy`,
    payload,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
        token,
      },
    }
  );
  return response;
};

export const sendOTP = (payload) => async (dispatch) => {
  try {
    // JWT token from the store
    const token = await store.getState().auth.token;

    // mobilePhone form the store
    const mobileNumber = await store.getState().products.companyDetails
      .mobilePhone;

    // Calling the SendOTP API
    const response = await axios.post(
      `${CONSTANTS.BASEURL}/pl/policies/otp/sendOTP`,
      {
        ...payload,
        productId: CONSTANTS.PRODUCT_INSTANCE_ID, // product instance ID from the constance
        mobilePhoneCode: "+27",
        mobilePhone: mobileNumber,
      },
      {
        headers: {
          "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
          token,
        },
      }
    );
    toast.success("OTP Sent successfully.");
    return response;
  } catch (err) {
    toast.error(err?.response?.data || "Error occured. Please try again !");
  }
};

export const validateOTP = (payload) => async (dispatch) => {
  // JWT token from the store
  const token = await store.getState().auth.token;

  // Calling the Validate API
  const response = await axios.post(
    `${CONSTANTS.BASEURL}/pl/policies/otp/validateOTP`,
    payload,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
        token,
      },
    }
  );
  return response.data;
};

export const makeExternalPayment = (payload) => async (dispatch) => {
  // JWT token from the store
  const token = await store.getState().auth.token;

  // Calling the external payment API
  const response = await axios.post(
    process.env.REACT_APP_ENV_TYPE === 'PROD' ?
    `${PRODUCTION_CONSTANTS.POLICY}/policies/payments/submitExternalPayment`
      :
    `${CONSTANTS.BASEURL}/pl/policies/payments/submitExternalPayment`,
    payload,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
        token,
      },
    }
  );
  return response;
};

export const makePolicySalePayment = (payload) => async (dispatch) => {
  // JWT token from the store
  const token = await store.getState().auth.token;

  // Calling the makePolicySalePayment API
  const response = await axios.post(
    process.env.REACT_APP_ENV_TYPE === 'PROD' ?
    `${PRODUCTION_CONSTANTS.POLICY}/policies/sale/makePolicySalePayment`
      :
    `${CONSTANTS.BASEURL}/pl/policies/sale/makePolicySalePayment`,
    payload,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
        token,
      },
    }
  );
  return response;
};

export const activatePolicy = (payload) => async (dispatch) => {
  // JWT token from the store
  const token = await store.getState().auth.token;

   // Calling the activatePolicy API
  const response = await axios.post(
    process.env.REACT_APP_ENV_TYPE === 'PROD' ?
    `${PRODUCTION_CONSTANTS.POLICY}/policies/sale/activatePolicySale`
      :
    `${CONSTANTS.BASEURL}/pl/policies/sale/activatePolicySale`,
    payload,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
        token,
      },
    }
  );
  return response;
};


export const submitPolicy = (payload,history) => async (dispatch) => {
  // JWT token from the store
  const token = await store.getState().auth.token;
 
   // Calling the activatePolicy API
   const response = await axios.post(
     `${CONSTANTS.MIDDLEWARE_URL}/policy/submitMobilePayment`,
     payload,
     {
       headers: {
         "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
         token,
       },
     }
   );
  const { code, statusName } = response?.data?.data;
  if (statusName === 'Failed' || code === '900.100.201') {
    return history({pathname:"/error-occured", state:{code : 'MOBILE_ERROR', message:'Please check your mobile number'}})
  }

  dispatch({
    type: 'SUBMIT_MOBILE_PAYMENT_REQUEST',
    payload
  })
  dispatch({
    type: "SUBMIT_MOBILE_PAYMENTSUCCESS",
    payload: response?.data?.data,
  });
};



export const checkPaymentStatus = (paymentReferenceId,payload,history) => async (dispatch) => {
  // JWT token from the store
  const token = await store.getState().auth.token;

   // Calling the activatePolicy API
   const response = await axios.post(
     `${CONSTANTS.MIDDLEWARE_URL}/queryMobilePayment/${paymentReferenceId}`,
     payload,
     {
       headers: {
         "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
         token,
       },
     }
   );
  dispatch({
    type: "PAYMENT_RESPONSE",
    payload: response?.data?.data,
  });
};


export const makeSalePayment = (payload,history) => async (dispatch) => {
  // JWT token from the store
  const token = await store.getState().auth.token;

   // Calling the activatePolicy API
   const response = await axios.post(
   process.env.REACT_APP_ENV_TYPE === 'PROD' ?
    `${PRODUCTION_CONSTANTS.POLICY}/policies/policy/makeSalePayment`
      :
    `${CONSTANTS.BASEURL}/pl/policies/policy/makeSalePayment`,
    payload,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
        token,
      },
    }
  );
  return response;
};

export const getProductPayment = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      // JWT token from the store
     const token = await store.getState().auth.token;
      // Calling the product types
      const response = await axios.get(
        `${CONSTANTS.MIDDLEWARE_URL}/products/getProductPayment/${CONSTANTS.PRODUCT_INSTANCE_ID}`,
        {
          headers: {
            "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
            token,
          },
        }
      );
      // Dispatching ----> response data to the store
      dispatch({ type: "PRODUCT_PAYMENT", payload: response.data });
      resolve(response.data.data);
    } catch (err) {
      reject(err);
    }
  });
};

export const generateCheckout = (payload) => async (dispatch) => {
  // JWT token from the store
  const token = await store.getState().auth.token;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${CONSTANTS.MIDDLEWARE_URL}/products/Payment/GenerateCheckout`,
        payload,
        {
          headers: {
            "Ocp-Apim-Subscription-Key": CONSTANTS.PAYMENT_SUBSCRIPTION_KEY,                
            token,
          },
        }
      );
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const getPaymentStatus =
  (policyReference, PolicySaleReference) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        // JWT token from the store
      const token = await store.getState().auth.token;

        // Calling the product types
        const response = await axios.get(
          `${CONSTANTS.MIDDLEWARE_URL}/products/getPaymentStatus/${policyReference}/${PolicySaleReference}`,
          {
            headers: {
              "Ocp-Apim-Subscription-Key": CONSTANTS.PAYMENT_SUBSCRIPTION_KEY,
              token,
            },
          }
        );
        resolve(response.data.data);
      } catch (err) {
        reject(err);
      }
    });
  };

export const checkTC = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_T&C',
    payload
  })
}
export const clearRiskState = () => async (dispatch) => {
  const riskAttributes = store.getState()?.products?.riskAttributes.map((x) => ({ ...x, attributes: x.attributes.map((y) => ({ ...y, value: null  })) }));
  dispatch({
    type: 'CLEAR_RISK_STATE',
    payload: riskAttributes
  })
}

export const rejectPolicy = (data) => async (dispatch) => {
  const token = await store.getState().auth.token;

	const response = await axios.post(
    `${CONSTANTS.MIDDLEWARE_URL}/policy/rejectPolicy`,
    data,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
        token,
      },
    }
  );
	return response;
};

export const approvePolicy = (payload) => async (dispatch) => {
  // JWT token from the store
  const token = await store.getState().auth.token;

  // Calling the issue policy sale API
  const response = await axios.post(
    `${CONSTANTS.MIDDLEWARE_URL}/policy/approvePolicy`,
    payload,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": CONSTANTS.OCP_APIM_SUBSCRIPTION_KEY,
        token,
      },
    }
  );
  return response;
};
