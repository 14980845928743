import { Divider } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import style from "../components.module.css";

const Summary = ({
  title,
  referenceNumber,
  policyHolder,
  policyHolderSurname,
  premiumAmount,
  coverAmount,
  isSuccess = false,
  isFail = false,
  isPass = false,
  isPending = false,
}) => {
  return (
    <div className={style.summary_container}>
      {isFail ? (
        <Row className="justify-content-center">
          <Col lg={12}>
            <h3 className="primary-color f-700 text-center">
              Oops Transaction Failed!
            </h3>
            <p className="primary-color f-700 text-center">
              We seem to struggle with the payment transaction.
            </p>
          </Col>
        </Row>
      ) : isPass ? (
        <Row className="justify-content-center">
          <Col lg={12}>
            <h3 className="primary-color f-700 text-center">All Done!</h3>
            {/* <p className="primary-color f-700 text-center">
              Thanks, payment received
            </p> */}
            <p className="primary-color f-700 text-center">
              Payment successful
            </p>
          </Col>
        </Row>
      ) : isPending ? (
        <Row className="justify-content-center">
          <Col lg={12}>
            <h3 className="primary-color f-700 text-center">All done validating payment</h3>
            {/* <p className="primary-color f-700 text-center">
              Thanks, payment received
            </p> */}
            <p className="primary-color f-700 text-center">Payment Pending</p>
            <p className="primary-color f-600 text-center">
              You will receive the policy confirmation email upon payment
              confirmation
            </p>
          </Col>
        </Row>
      ) : (
        <></>
      )}

      <Row className="justify-content-between mt-4">
        <Col xs={6} lg={6}>
          <p className="primary-color f-700 text-left">Reference number :</p>
        </Col>
        <Col xs={6} lg={6}>
          <p className="primary-color f-700 text-right w-100">
            {referenceNumber}
          </p>
        </Col>
      </Row>

      <Row className="justify-content-between mt-0">
        <Col xs={6} lg={6}>
          <p className="primary-color f-700 text-left">Policy holder :</p>
        </Col>
        <Col xs={6} lg={6}>
          <p className="primary-color f-700 text-right w-100">
            {policyHolder} {policyHolderSurname}
          </p>
        </Col>
      </Row>

      <Divider />

      <Row className="justify-content-between mt-4">
        <Col xs={6} lg={6}>
          <p className="primary-color f-700 text-left">Annual Premium :</p>
        </Col>
        <Col xs={6} lg={6}>
          <p className="primary-color f-700 text-right w-100">
            KSh {premiumAmount}
          </p>
        </Col>
      </Row>

      {!isFail && (
        <Row className="justify-content-between mt-1">
          <Col xs={6} lg={6}>
            <p className="primary-color f-700 text-left">Cover Amount :</p>
          </Col>
          <Col xs={6} lg={6}>
            <p className="primary-color f-700 text-right w-100">
              KSh {coverAmount}
            </p>
          </Col>
        </Row>
      )}

      {isFail && (
        <>
          <Divider />

          <Row className="justify-content-between mt-3">
            <p className="primary-color f-300  w-100">
              Please use any of the following payment methods to submit your
              premium payment to complete this application process.
            </p>
          </Row>

          <Row className="justify-content-between mt-3">
            <Col lg={12}>
              <p className="primary-color f-700  w-100">Bank details</p>
              <ul>
                <li className="primary-color">
                  <span className="primary-color f-300  w-100">Bank:</span>{" "}
                  <span className="primary-color f-700  w-100">
                    Standard Chartered Bank Ltd.
                  </span>
                </li>
                <li className="primary-color">
                  <span className="primary-color f-300  w-100">
                    Account Name:
                  </span>{" "}
                  <span className="primary-color f-700  w-100">
                    Sanlam Life Insurance Ltd.
                  </span>
                </li>
                <li className="primary-color">
                  <span className="primary-color f-300  w-100">
                    Account Number:
                  </span>{" "}
                  <span className="primary-color f-700  w-100">
                    {" "}
                    0104021198704
                  </span>
                </li>
                <li className="primary-color">
                  <span className="primary-color f-300  w-100">
                    {" "}
                    Branch Name
                  </span>{" "}
                  <span className="primary-color f-700  w-100">
                    Kenyatta Avenue
                  </span>
                </li>
                <li className="primary-color">
                  <span className="primary-color f-300  w-100">
                    {" "}
                    Reference number :
                  </span>{" "}
                  <span className="primary-color f-700  w-100">
                    {referenceNumber}
                  </span>
                </li>
              </ul>
            </Col>
          </Row>

          <Row className="justify-content-between mt-3">
            <Col lg={12}>
              <p className="primary-color f-700  w-100">M-PESA details</p>
              <ul>
                <li className="primary-color">
                  <span className="primary-color f-300  w-100">
                    {" "}
                    Pay bill Name
                  </span>{" "}
                  <span className="primary-color f-700  w-100">
                    Sanlam Funeral Cover
                  </span>
                </li>
                <li className="primary-color">
                  <span className="primary-color f-300  w-100">
                    {" "}
                    Pay bill Number:
                  </span>{" "}
                  <span className="primary-color f-700  w-100">65100</span>
                </li>
                <li className="primary-color">
                  <span className="primary-color f-300  w-100">
                    {" "}
                    Account number :
                  </span>{" "}
                  <span className="primary-color f-700  w-100">
                    {referenceNumber}
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
        </>
      )}

      <Divider />

      <Row className="justify-content-start mt-3">
        <Col lg={12}>
          <p className="primary-color f-700 text-left m-0 w-100">
            Support contact details:
          </p>
          <ul>
            <li className="primary-color f-500 text-left m-0 w-100">
              ke.bancassurance@letshego.com
            </li>
            <li className="primary-color f-500 text-left m-0 w-100">
              +254 780 222 131
            </li>
          </ul>
        </Col>
      </Row>

      <Divider />

      <Row className="justify-content-start mt-3">
        <p className="primary-color f-700 text-center">
          Use the navigation bar on the left to return to the home page
        </p>
      </Row>
    </div>
  );
};

export default Summary;
