import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import ReactSelect from "react-select";
import {
  DateInput,
  Info,
  NumberInput,
  Select,
  CountryInput,
  TextInput,
} from "../../../components";
import NavigationButtons from "../../../components/NavigationButtons";
import style from "../Form.module.css";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../../utils/useWindowDimension";
import classNames from "classnames";
import {
  CreatePolicy,
  updateAccordion,
  updateCustomAttributes,
  updatedAttributes,
} from "../../../store/actions/products";
import { validate } from "validate.js";
import CONSTANTS, { ATTRIBUTES, RISKS } from "../../../utils/constants";
import Loader from "../../../components/Loader";
import {trackUserEvents } from "../../../utils/utils";

const Step2 = ({ handleNext, handleBack }) => {
  const childState = useSelector((state) => state.products?.childAttributes);

  const parentState = useSelector((state) => state.products?.parentAttributes);

  const [childAttributes, setChildAttributes] = useState(childState);

  const [parentAttributes, setParentAttributes] = useState(parentState);
  const phoneCodeAttributes = useSelector(
    (state) => state.products?.phoneCodeAttributes
  );

  const riskAttributes = useSelector((state) =>
    state.products?.riskAttributes?.map((x) => ({
      ...x,
      attributes: x.attributes.map((y) => ({ ...y, code: x.code })),
    }))
  );

  const customAttributes = useSelector((state) => state.products.customAttributes); 

  const protectionRequirement = riskAttributes
    .find((x) => x.code === RISKS.QUQ)
    ?.attributes?.find((y) => y.name === ATTRIBUTES.PROTECTION_REQUIREMENT);
  const childCoverAttribute = riskAttributes
    .find((z) => z.code === RISKS.QUQ)
    ?.attributes.find((x) => x.name === ATTRIBUTES.CHILD_COVER);
  const numberOfChildrens = riskAttributes
    .find((z) => z.code === RISKS.QUQ)
    ?.attributes.find((x) => x.name === ATTRIBUTES.NUMBER_OF_CHILDREN);
  const parentCoverAttribute = riskAttributes
    .find((z) => z.code === RISKS.QUQ)
    ?.attributes.find((x) => x.name === ATTRIBUTES.PARENTAL_COVER);
  const spouseCoverAttribute = riskAttributes
    .find((z) => z.code === RISKS.QUQ)
    ?.attributes.find((x) => x.name === ATTRIBUTES.SPOUSE_COVER);
  const totalParents = () => {
    var total = riskAttributes
      ?.find((z) => z.code === RISKS.QUQ)
      ?.attributes?.filter(
        (x) =>
          x.name === ATTRIBUTES.PARENT_55 ||
          x.name === ATTRIBUTES.PARENT_65 ||
          x.name === ATTRIBUTES.PARENT_80
      );
    return total?.reduce((a, b) => a + (b?.value ?? 0), 0);
  };
  const productionRequirement = riskAttributes
    .find((z) => z.code === RISKS.QUQ)
    ?.attributes.find((x) => x.name === ATTRIBUTES.PROTECTION_REQUIREMENT);

  const individualAttribute = useSelector(
    (state) => state?.products?.protectionIndividual
  );
  const familyAttribute = useSelector(
    (state) => state?.products?.protectionFamily
  );

  const dispatch = useDispatch();
  const handleChange = (evt, instanceId, code, name) => {
    if (name === "mobilePhoneCode") {
      dispatch(
        updateCustomAttributes({
          type: "PHONE_CODE_ATTRIBUTES",
          payload: { ...phoneCodeAttributes, policyPhoneCode: `${evt}` },
        })
      );
    } else if (name === "mobileSpousePhoneCode") {
      dispatch(
        updateCustomAttributes({
          type: "PHONE_CODE_ATTRIBUTES",
          payload: { ...phoneCodeAttributes, spousePhoneCode: `${evt}` },
        })
      );
    } else {
      var attribute = riskAttributes.find((x) => x.code === code)?.attributes;
      var arr = [...attribute];
      var index = attribute.findIndex((x) => x.instanceId === instanceId);
      arr[index] = { ...arr[index], value: evt };
      dispatch(updatedAttributes(arr, code));
    }
  };

  const handleCustomChange = (evt, name, index) => {
    var arr = [...childAttributes];
    arr[index] = { ...arr[index], [name]: evt };
    setChildAttributes(arr);
    dispatch(
      updateCustomAttributes({
        type: "CHILD_ATTRIBUTES",
        payload: arr,
      })
    );
  };

  const handleCustomParentChange = (evt, name, index) => {
    var arr = [...parentAttributes];
    arr[index] = { ...arr[index], [name]: evt };
    setParentAttributes(arr);
    dispatch(
      updateCustomAttributes({
        type: "PARENT_ATTRIBUTES",
        payload: arr,
      })
    );
  };

  const calculateMinDate = (type) => {
    const date = new Date();
    if (type === "child") {
      date.setFullYear(date.getFullYear() - 24);
    } else {
      date.setFullYear(date.getFullYear() - 80);
    }

    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const calculateMaxDate = (type) => {
    const date = new Date();
    if (type === "child") {
      return new Date();
    } else {
      date.setFullYear(date.getFullYear() - 18);
    }

    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const [errors, setErrors] = useState({});
  const renderComponents = useCallback(
    (item, custom, index) => {
      switch (item.dataType) {
        case 1:
          var maxLength = null;
          if (
            item.instanceId === "bd4ca033-4017-470c-954b-46e45bb64054" ||
            item.instanceId === "e7f9f020-0ffd-452d-9a1f-d615ca11a5f3" ||
            item.instanceId === "007033e0-15da-4cf0-9072-52080a30b5ba" ||
            item.instanceId === "fbd911b9-3275-406e-8361-2953d9f17b1d" ||
            item.instanceId === "8fbb3bc8-8ba0-48e8-a242-c2592b313198" ||
            item.instanceId === "cff792b7-1927-4a13-91f7-89e5452af9ff" ||
            item.instanceId === "fa3e9e00-e867-4747-8bea-2d63940965b3" ||
            item.instanceId === "652b8748-5a50-45d0-9bfd-7637d439c854" ||

            // PROD INSTANCE ID's
            item.instanceId === "d51719d8-f07c-4c02-ab63-1cea4d90ecaa" ||
            item.instanceId === "4ce38063-2024-4389-a4c0-8c19e0e2a055" ||
            item.instanceId === "ab9479f8-8d7e-402f-860c-390cfedcad2c" ||
            item.instanceId === "bb06519a-6098-4344-b162-5213e18acaf2" ||
            item.instanceId === "0cc2ba32-c2bd-40f1-80c1-f9e3fcbeafd4" ||
            item.instanceId === "d5f121ef-a52e-41b1-9b9c-a750be1177d3" ||
            item.instanceId === "98876d7f-c93e-462c-acb7-fd66e769126e" ||
            item.instanceId === "4050456b-829f-47a2-b4c5-7401ffeccb33" ||
            item.instanceId === "4050456b-829f-47a2-b4c5-7401ffeccb33"

          ) {
            maxLength = 30;
          }
          return (
            <TextInput
              id={item.name}
              label={item.description}
              errors={
                custom === "spouse"
                  ? errors[item.name]
                  : custom
                  ? errors[`${item.name} ${index + 1}`]
                  : errors[item.name]
              }
              name={item.name}
              maxLength={maxLength}
              value={
                custom === "child"
                  ? childState[index]
                    ? childState[index][item?.name]
                    : null
                  : custom === "parent"
                  ? parentState[index]
                    ? parentState[index][item?.name]
                    : null
                  : item?.value
              }
              onChange={(e) =>
                custom === "child"
                  ? handleCustomChange(e.target.value, item.name, index)
                  : custom === "parent"
                  ? handleCustomParentChange(e.target.value, item.name, index)
                  : handleChange(e.target.value, item.instanceId, item.code)
              }
              required={item.isRequired}
            />
          );
        case 2:
          return (
            <div className="row">
              {width > 1024 ? (
                <>
                  <div className="col-4">
                    <CountryInput
                      id={"mobilePhoneCode"}
                      label={"Country code"}
                      name={"mobilePhoneCode"}
                      errors={
                        custom === "spouse"
                          ? errors?.["spousePhoneCode"]
                          : custom === "parent"
                          ? errors?.[`ParentMobileNumbercode ${index + 1}`]
                          : custom
                          ? errors[`${item.name} code ${index + 1}`]
                          : errors?.["policyPhoneCode"]
                      }
                      value={custom === "spouse" ? phoneCodeAttributes?.spousePhoneCode : phoneCodeAttributes?.policyPhoneCode}
                      placeholder={"+255"}
                      onChange={(e) =>
                        custom === "parent"
                          ? handleCustomParentChange(
                              e,
                              "ParentMobileNumbercode",
                              index
                            )
                          : custom === "spouse"
                          ? handleChange(
                              e,
                              null,
                              item.code,
                              "mobileSpousePhoneCode"
                            )
                          : handleChange(e, null, item.code, "mobilePhoneCode")
                      }
                      required={true}
                      country={true}
                    />
                  </div>
                  <div className="col-8">
                    <NumberInput
                      id={item.name}
                      label={item.description}
                      errors={
                        custom === "spouse"
                          ? errors[item.name]
                          : custom
                          ? ""
                          : errors[item.name]
                      }
                      name={item.name}
                      placeholder={"123456789"}
                      value={
                        custom === "child"
                          ? childState[index]
                            ? childState[index][item?.name]
                            : null
                          : custom === "parent"
                          ? parentState[index]
                            ? parentState[index][item?.name]
                            : null
                          : item?.value
                      }
                      onChange={(e) =>
                        custom === "child"
                          ? handleCustomChange(e.target.value, item.name, index)
                          : custom === "parent"
                          ? handleCustomParentChange(
                              Number(e.target.value) === 0 ||
                                e.target.value === undefined ||
                                e.target.value === null
                                ? ""
                                : Number(e.target.value).toString(),
                              item.name,
                              index
                            )
                          : handleChange(
                              Number(e.target.value) === 0 ||
                                isNaN(Number(e.target.value)) ||
                                e.target.value === undefined ||
                                e.target.value === null
                                ? ""
                                : Number(e.target.value).toString(),
                              item.instanceId,
                              item.code
                            )
                      }
                      required={item.isRequired}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12">
                    <CountryInput
                      id={"mobilePhoneCode"}
                      label={"Country code"}
                      name={"mobilePhoneCode"}
                      errors={
                        custom === "spouse"
                          ? errors?.["spousePhoneCode"]
                          : custom === "parent"
                          ? errors?.[`ParentMobileNumbercode ${index + 1}`]
                          : custom
                          ? errors[`${item.name} code ${index + 1}`]
                          : errors?.["policyPhoneCode"]
                      }
                        value={custom === "spouse" ? phoneCodeAttributes?.spousePhoneCode : phoneCodeAttributes?.policyPhoneCode}
                      placeholder={"+255"}
                      onChange={(e) =>
                        custom === "parent"
                          ? handleCustomParentChange(
                              e,
                              "ParentMobileNumbercode",
                              index
                            )
                          : custom === "spouse"
                          ? handleChange(
                              e,
                              null,
                              item.code,
                              "mobileSpousePhoneCode"
                            )
                          : handleChange(e, null, item.code, "mobilePhoneCode")
                      }
                      required={true}
                      country={true}
                    />
                  </div>
                  <div className="col-12">
                    <NumberInput
                      id={item.name}
                      label={item.description}
                      errors={
                        custom === "spouse"
                          ? errors[item.name]
                          : custom
                          ? ""
                          : errors[item.name]
                      }
                      name={item.name}
                      placeholder={"123456789"}
                      value={
                        custom === "child"
                          ? childState[index]
                            ? childState[index][item?.name]
                            : null
                          : custom === "parent"
                          ? parentState[index]
                            ? parentState[index][item?.name]
                            : null
                          : item?.value
                      }
                      onChange={(e) =>
                        custom === "child"
                          ? handleCustomChange(e.target.value, item.name, index)
                          : custom === "parent"
                          ? handleCustomParentChange(
                              Number(e.target.value) === 0 ||
                                e.target.value === undefined ||
                                e.target.value === null
                                ? ""
                                : Number(e.target.value).toString(),
                              item.name,
                              index
                            )
                          : handleChange(
                              Number(e.target.value) === 0 ||
                                isNaN(Number(e.target.value)) ||
                                e.target.value === undefined ||
                                e.target.value === null
                                ? ""
                                : Number(e.target.value).toString(),
                              item.instanceId,
                              item.code
                            )
                      }
                      required={item.isRequired}
                    />
                  </div>
                </>
              )}
            </div>
          );

        case 5:
          return (
            <DateInput
              id={item.name}
              label={item.description}
              errors={
                custom === "spouse"
                  ? errors[item.name]
                  : custom
                  ? errors[`${item.name} ${index + 1}`]
                  : errors[item.name]
              }
              name={item.name}
              minDate={calculateMinDate(custom)}
              maxDate={calculateMaxDate(custom)}
              custom={custom}
              value={
                custom === "child"
                  ? childState[index]
                    ? childState[index][item?.name]
                    : null
                  : custom === "parent"
                  ? parentState[index]
                    ? parentState[index][item?.name]
                    : null
                  : item?.value
              }
              onChange={(e) =>
                custom === "child"
                  ? handleCustomChange(e.target.value, item.name, index)
                  : custom === "parent"
                  ? handleCustomParentChange(e.target.value, item.name, index)
                  : handleChange(e.target.value, item.instanceId, item.code)
              }
              required={item.isRequired}
            />
          );
        case 7:
          return (
            <Select
              id={item.name}
              title={item.description}
              errors={
                custom ? errors[`${item.name} ${index + 1}`] : errors[item.name]
              }
              half={true}
              name={item.name}
              width={150}
              isMobile={width < 700}
              items={JSON.parse(item.settings)?.items?.map((x) => ({
                name: x,
                value: x,
              }))}
              options={JSON.parse(item.settings)
                ?.items?.filter((f) => !getRelationShips()?.includes(f) )
                ?.map((x) => ({
                  name: x,
                  value: x,
                }))}
              value={
                custom === "child"
                  ? childState[index]
                    ? childState[index][item?.name]
                    : null
                  : custom === "parent"
                  ? parentState[index]
                    ? parentState[index][item?.name]
                    : null
                  : item?.value
              }
              defaultValue={JSON.parse(item.settings)?.items[0]}
              onClick={(e) =>
                custom === "child"
                  ? handleCustomChange(
                      { target: { value: e, name: item.name } },
                      item.name,
                      index
                    )
                  : custom === "parent"
                  ? handleCustomParentChange(e, item.name, index)
                  : handleChange(
                      { target: { value: e, name: item.name } },
                      item.instanceId,
                      item.code
                    )
              }
              required={item.isRequired}
            />
          );
      }
    },
    [errors, riskAttributes]
  );
  const { width } = useWindowDimensions();

  const accordionState = useSelector((state) => state?.products?.accordion);

  const [expand, setExpand] = useState({
    ...accordionState,
  });

  const agentOnBoarding = useSelector((state) => state?.auth?.agentOnboarding);

  const premiumAmount = useSelector((state) => state?.products?.premium);

  const reducerState = useSelector((state) => state?.products);

  const getRelationShips = () => {
    let arr = [];
    parentState?.forEach((d) => {
      arr.push(d.Relationship)
    })
    return arr;
  }
  const getRows = (rows) => {
    var arr = [];
    for (var i = 0; i < rows; i++) {
      arr.push(i + 1);
    }
    return arr;
  };

  const [isLoading, setIsLoading] = useState(false);

  const idNumberValidation = () => {
    var val = riskAttributes
      .find((x) => x.code === "PLA")
      ?.attributes?.find((y) => y.name === "Date of Birth")?.value;
    var idNumber = riskAttributes
      .find((x) => x.code === "PLA")
      ?.attributes?.find((y) => y.name === "ID Number")?.value;
    if (!val || !idNumber) {
      return true;
    }
    if (idNumber?.length !== 8) {
      return true;
    }
  };
  const getConstraints = () => {
    var val = {};

    val = {
      ...val,
      ["policyPhoneCode"]: {
        presence: {
          allowEmpty: false,
        },
        length: {
          maximum: 4,
          message: "^ Mobile phone code is too long (max is 4 characters)",
        },
      },
    };

    riskAttributes
      .find((x) => x.code === "PLA")
      ?.attributes.forEach((y, i) => {
        if (y.name === "Mobile Number") {
          val = {
            ...val,
            [y.name]: {
              presence: {
                allowEmpty: false,
              },
              format: {
                pattern: /^(\+\d{1,3}[- ]?)?\d{9,12}$/,
                message: "^ Invalid mobile number",
              },
            },
          };
        } else if (y.name === "ID Number") {
          val = {
            ...val,
            [y.name]: {
              presence: {
                allowEmpty: false,
              },
            },
          };
        } else if (y.name === "E-Mail Address") {
          val = {
            ...val,
            [y.name]: {
              presence: {
                allowEmpty: false,
                message: "^Email address can't be blank",
              },
              email: true,
            },
          };
        } else if (y.name === "Name") {
          val = {
            ...val,
            [y.name]: {
              presence: {
                allowEmpty: false,
                message: "^First name can't be blank",
              },
              format: {
                pattern: /^[A-Za-z]+$/,
                message: "^First name can only contain alphabets",
              },
            },
          };
        } else if (y.name === "Last Name") {
          val = {
            ...val,
            [y.name]: {
              presence: {
                allowEmpty: false,
              },
              format: {
                pattern: /^[A-Za-z]+$/,
                message: "can only contain alphabets",
              },
            },
          };
        } else if (y.name === "KRA Pin") {
          val = {
            ...val,
            [y.name]: {
              presence: {
                allowEmpty: false,
                message: "^KRA Pin can't be blank",
              },
              format: {
                pattern: /^[A-Za-z][0-9]{9}[A-Za-z]$/,
                message: "^KRA Pin is Invalid",
              },
            },
          };
        } else {
          val = {
            ...val,
            [y.name]: {
              presence: {
                allowEmpty: false,
              },
            },
          };
        }
      });

      if (customAttributes?.coverOption === "Family") {
      if (
        riskAttributes
          ?.find((x) => x.code === RISKS.QUQ)
          ?.attributes?.find((z) => z.name === ATTRIBUTES.SPOUSE_COVER)
          ?.value === 0
      ) {
        riskAttributes
          .find((x) => x.code === "SLA")
          ?.attributes.forEach((y, i) => {
            if (y.name === "Spouse Name") {
              val = {
                ...val,
                [y.name]: {
                  presence: {
                    allowEmpty: false,
                  },
                  format: {
                    pattern: /^[A-Za-z]+$/,
                    message: "can only contain alphabets",
                  },
                },
              };
            } else if (y.name === "Spouse Last Name") {
              val = {
                ...val,
                [y.name]: {
                  presence: {
                    allowEmpty: false,
                  },
                  format: {
                    pattern: /^[A-Za-z]+$/,
                    message: "can only contain alphabets",
                  },
                },
              };
            } else if (y.name === "Spouse Mobile Number") {
              val = {
                ...val,
                [y.name]: {
                  presence: {
                    allowEmpty: false,
                  },
                  format: {
                    pattern: /^(\+\d{1,3}[- ]?)?\d{9,12}$/,
                    message: "^ Invalid mobile number",
                  },
                },
              };
              val = {
                ...val,
                ["spousePhoneCode"]: {
                  presence: {
                    allowEmpty: false,
                  },
                  length: {
                    maximum: 4,
                    message:
                      "^ Mobile phone code is too long (max is 4 characters)",
                  },
                },
              };
            } else {
              val = {
                ...val,
                [y.name]: {
                  presence: {
                    allowEmpty: false,
                  },
                },
              };
            }
          });
      }

      if (
        riskAttributes
          ?.find((x) => x.code === RISKS.QUQ)
          ?.attributes?.find((z) => z.name === ATTRIBUTES.PARENTAL_COVER)
          ?.value === 0
      ) {
        getRows(totalParents()).map((x, i) => {
          val = {
            ...val,
            [`Parent Name ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              length: {
                maximum: 30,
              },
              format: {
                pattern: /^[A-Za-z]+$/,
                message: "can only contain alphabets",
              },
            },
            [`Parent Last Name ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              length: {
                maximum: 30,
              },
              format: {
                pattern: /^[A-Za-z]+$/,
                message: "can only contain alphabets",
              },
            },
            [`Parent Date of Birth ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
            },
            [`Relationship ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
            },
            [`ParentMobileNumbercode ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              length: {
                maximum: 4,
                message:
                  "^ Mobile phone code is too long (max is 4 characters)",
              },
            },
          };
        });
      }

      if (
        riskAttributes
          ?.find((x) => x.code === RISKS.QUQ)
          ?.attributes?.find((z) => z.name === ATTRIBUTES.CHILD_COVER)
          ?.value === 0
      ) {
        getRows(
          riskAttributes
            ?.find((x) => x.code === RISKS.QUQ)
            ?.attributes?.find((z) => z.name === ATTRIBUTES.NUMBER_OF_CHILDREN)
            ?.value
        ).map((x, i) => {
          val = {
            ...val,
            [`Child Name ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              format: {
                pattern: /^[A-Za-z]+$/,
                message: "can only contain alphabets",
              },
            },
            [`Child Last Name ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              format: {
                pattern: /^[A-Za-z]+$/,
                message: "can only contain alphabets",
              },
            },
            [`Date of Birth ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
            },
          };
        });
      }
    }

    if (beneficiaries?.length) {
      getRows(beneficiaries?.length).map((x, i) => {
        val = {
          ...val,
          [`First Name ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
            format: {
              pattern: /^[A-Za-z]+$/,
              message: "can only contain alphabets",
            },
          },
          [`Last Name ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
            format: {
              pattern: /^[A-Za-z]+$/,
              message: "can only contain alphabets",
            },
          },
          [`Email ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
            email: true,
          },
          [`mobilePhoneCode ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
            length: {
              maximum: 4,
              message: "^ Mobile phone code is too long (max is 4 characters)",
            },
          },
          [`Phone Number ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
            format: {
              pattern: /^(\+\d{1,3}[- ]?)?\d{9,12}$/,
              message: "^ Invalid mobile number",
            },
          },
          [`Percentage ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
          },
        };
      });
    }

    delete val["P.O. Box"];
    return val;
  };

  const handleSubmit = async () => {
    var values = {
      policyPhoneCode: phoneCodeAttributes?.policyPhoneCode,
      spousePhoneCode: phoneCodeAttributes?.spousePhoneCode,
    };
    riskAttributes
      .find((x) => x.code === "PLA")
      ?.attributes.forEach((y, i) => {
        values = {
          ...values,
          [y.name]: y.value,
        };
      });

    var policyValidations = [
      "Name",
      "Last Name",
      "Date of Birth",
      "Mobile Number",
      "ID Number",
      "KRA Pin",
      "E-Mail Address",
    ];
    var spouseValidations = [];
    var childValidation = [];
    var parentValidation = [];

      if (customAttributes?.coverOption === "Family") {
      if (
        riskAttributes
          ?.find((x) => x.code === RISKS.QUQ)
          ?.attributes?.find((z) => z.name === ATTRIBUTES.SPOUSE_COVER)
          ?.value === 0
      ) {
        riskAttributes
          .find((x) => x.code === "SLA")
          ?.attributes.forEach((y, i) => {
            spouseValidations.push(y.name);
            values = {
              ...values,
              [y.name]: y.value,
            };
          });
      }
      if (
        riskAttributes
          ?.find((x) => x.code === RISKS.QUQ)
          ?.attributes?.find((z) => z.name === ATTRIBUTES.PARENTAL_COVER)
          ?.value === 0
      ) {
        getRows(totalParents()).map((x, i) => {
          parentValidation.push(`Parent Name ${i + 1}`);
          parentValidation.push(`Parent Last Name ${i + 1}`);
          parentValidation.push(`Parent Date of Birth ${i + 1}`);
          values = {
            ...values,
            [`Parent Name ${i + 1}`]: Object.keys(parentState[i] ?? {})?.length
              ? parentState[i]["Parent Name"]
              : null,
            [`Parent Last Name ${i + 1}`]: Object.keys(parentState[i] ?? {})
              ?.length
              ? parentState[i]["Parent Last Name"]
              : null,
            [`Parent Date of Birth ${i + 1}`]: Object.keys(parentState[i] ?? {})
              ?.length
              ? parentState[i]["Parent Date of Birth"]
              : null,
            [`Relationship ${i + 1}`]: Object.keys(parentState[i] ?? {})?.length
              ? parentState[i]["Relationship"]
              : null,
            [`ParentMobileNumbercode ${i + 1}`]: Object.keys(
              parentState[i] ?? {}
            )?.length
              ? parentState[i]["ParentMobileNumbercode"]
              : null,
          };
        });
      }

      if (
        riskAttributes
          ?.find((x) => x.code === RISKS.QUQ)
          ?.attributes?.find((z) => z.name === ATTRIBUTES.CHILD_COVER)
          ?.value === 0
      ) {
        getRows(
          riskAttributes
            ?.find((x) => x.code === RISKS.QUQ)
            ?.attributes?.find((z) => z.name === ATTRIBUTES.NUMBER_OF_CHILDREN)
            ?.value
        ).map((x, i) => {
          childValidation.push(`Child Name ${i + 1}`);
          childValidation.push(`Child Last Name ${i + 1}`);
          childValidation.push(`Date of Birth ${i + 1}`);
          values = {
            ...values,
            [`Child Name ${i + 1}`]: Object.keys(childState[i] ?? {})?.length
              ? childState[i]["Child Name"]
              : null,
            [`Child Last Name ${i + 1}`]: Object.keys(childState[i] ?? {})
              ?.length
              ? childState[i]["Child Last Name"]
              : null,
            [`Date of Birth ${i + 1}`]: Object.keys(childState[i] ?? {})?.length
              ? childState[i]["Date of Birth"]
              : null,
          };
        });
      }
    }

    let beneAttributes = [];

    if (beneficiaries?.length) {
      getRows(beneficiaries?.length).map((x, i) => {
        beneAttributes.push(`First Name ${i + 1}`);
        beneAttributes.push(`Last Name ${i + 1}`);
        beneAttributes.push(`Email ${i + 1}`);
        beneAttributes.push(`mobilePhoneCode ${i + 1}`);
        beneAttributes.push(`Percentage ${i + 1}`);
        values = {
          ...values,
          [`First Name ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})?.length
            ? beneficiaries[i]["firstName"]
            : null,
          [`Last Name ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})?.length
            ? beneficiaries[i]["lastName"]
            : null,
          [`Email ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})?.length
            ? beneficiaries[i]["email"]
            : null,
          [`mobilePhoneCode ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})
            ?.length
            ? beneficiaries[i]["mobilePhoneCode"]
            : null,
          [`Phone Number ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})?.length
            ? beneficiaries[i]["number"]
            : null,
          [`Percentage ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})?.length
            ? beneficiaries[i]["percentage"]
            : null,
        };
      });
    }

    const errors = validate(values, getConstraints());

    let err = { ...errors };

    if (err || err !== undefined) {
      if (beneficiaries?.length) {
        Object.keys(err)?.map((x) => {
          if (beneAttributes?.includes(x)) {
            err = {
              ...err,
              beneficiaries: ["Please fill beneficiaries details "],
            };
          }
        });
      }
    }

    if (err || err !== undefined) {
      Object.keys(err)?.map((x) => {
        if (policyValidations?.includes(x)) {
          err = {
            ...err,
            policy: ["Please fill policy holder details "],
          };
        }
      });
    }

    if (err || err !== undefined) {
        if (customAttributes?.coverOption === "Family") {
        if (
          riskAttributes
            ?.find((x) => x.code === RISKS.QUQ)
            ?.attributes?.find((z) => z.name === ATTRIBUTES.PARENTAL_COVER)
            ?.value === 0
        ) {
          Object.keys(err).map((x) => {
            if (parentValidation.includes(x)) {
              err = { ...err, parent: ["Please fill parent details "] };
            }
          });
        }
      }
    }
    if (err || err !== undefined) {
      if (
        riskAttributes
          ?.find((x) => x.code === RISKS.QUQ)
          ?.attributes?.find((z) => z.name === ATTRIBUTES.CHILD_COVER)
          ?.value === 0
      ) {
        Object.keys(err).map((x) => {
          if (childValidation.includes(x)) {
            err = { ...err, child: ["Please fill children details "] };
          }
        });
      }
    }

    if (err || err !== undefined) {
      if (
        riskAttributes
          ?.find((x) => x.code === RISKS.QUQ)
          ?.attributes?.find((z) => z.name === ATTRIBUTES.SPOUSE_COVER)
          ?.value === 0
      ) {
        Object.keys(err).map((x) => {
          if (spouseValidations.includes(x)) {
            err = { ...err, spouse: ["Please fill spouse details "] };
          }
        });
      }
    }

    if (idNumberValidation()) {
      var val = riskAttributes
        .find((x) => x.code === "PLA")
        ?.attributes?.find((y) => y.name === "Date of Birth")?.value;
      var idNumber = riskAttributes
        .find((x) => x.code === "PLA")
        ?.attributes?.find((y) => y.name === "ID Number")?.value;
      err = {
        ...err,
        ["ID Number"]: !idNumber?.length
          ? ["ID Number can't be blank"]
          : !val?.length
          ? ["Please select the date of birth field"]
          : ["Invalid ID number"],
      };
    }

    const beneCount = beneficiaries.reduce((a, b) => a + b.percentage, 0);

    if (beneCount !== 100) {
      err = {
        ...err,
        ["beneficiariesSplit"]: [
          `Benefit split for all beneficiaries must be total to 100%`,
        ],
      };
    } else {
      delete err?.beneficiariesSplit;
    }

    if (Object.keys(err)?.length !== 0) {
      setErrors(err);
      return;
    } else {
      setErrors({});
    }

    var policyRisks =
      // protectionRequirement?.value === 0
      customAttributes?.coverOption === "Just Me"
        ? [
            {
              externalReference: uuidv4(),
              attributes: [
                ...reducerState.riskAttributes
                  .find((x) => x.code === "QUQ")
                  ?.attributes.map((y) => ({
                    ...y,
                    value:
                      y.name === ATTRIBUTES.PROTECTION_REQUIREMENT
                          ? customAttributes?.coverOption === "Just Me"
                          ? "Individual"
                          : "Family"
                        : y.value,
                  })),
              ],
              valuations: individualAttribute?.[0]?.valuations.values,
              productRiskReference: reducerState.riskAttributes.find(
                (x) => x.code === "QUQ"
              )?.instanceId,
              price: premiumAmount,
              discount: 0,
              tax: 0,
              total: premiumAmount,
            },
            {
              externalReference: uuidv4(),
              attributes: [
                ...reducerState.riskAttributes.find((x) => x.code === "PLA")
                  ?.attributes,
              ],
              valuations: [],
              productRiskReference: reducerState.riskAttributes.find(
                (x) => x.code === "PLA"
              )?.instanceId,
              price: 0.0,
              discount: 0.0,
              tax: 0.0,
              total: 0.0,
            },
          ]
        : [
            {
              externalReference: uuidv4(),
              attributes: [
                ...reducerState.riskAttributes.find((x) => x.code === "PLA")
                  ?.attributes,
              ],
              valuations: [],
              productRiskReference: reducerState.riskAttributes.find(
                (x) => x.code === "PLA"
              )?.instanceId,
              price: 0.0,
              discount: 0.0,
              tax: 0.0,
              total: 0.0,
            },
            {
              externalReference: uuidv4(),
              attributes: [
                ...reducerState.riskAttributes
                  .find((x) => x.code === "QUQ")
                  ?.attributes.map((y) => ({
                    ...y,
                    value:
                      y.name === ATTRIBUTES.PROTECTION_REQUIREMENT
                        ? customAttributes?.coverOption === "Just Me"
                          ? "Individual"
                          : "Family"
                        : y.value,
                  })),
              ],
              valuations: familyAttribute?.[0]?.valuations.values,
              productRiskReference: reducerState.riskAttributes.find(
                (x) => x.code === "QUQ"
              )?.instanceId,
              price: premiumAmount,
              discount: 0,

              tax: 0,
              total: premiumAmount,
            },
            {
              externalReference: uuidv4(),
              attributes: [
                ...reducerState.riskAttributes.find((x) => x.code === "SLA")
                  ?.attributes,
              ],
              valuations: [],
              productRiskReference: reducerState.riskAttributes.find(
                (x) => x.code === "SLA"
              )?.instanceId,
              price: 0.0,
              discount: 0.0,
              tax: 0.0,
              total: 0.0,
            },
            ...childAttributes.map((x, i) => ({
              attributes: Object.keys(x).map((y) => ({ name: y, value: x[y] })),
              externalReference: uuidv4(),
              valuations: [],
              productRiskReference: reducerState.riskAttributes.find(
                (x) => x.code === "CLA"
              )?.instanceId,
              price: 0.0,
              discount: 0.0,
              tax: 0.0,
              total: 0.0,
            })),
            ...parentAttributes.map((x, i) => ({
              attributes: Object.keys(x).map((y) => ({ name: y, value: x[y] })),
              externalReference: uuidv4(),
              valuations: [],
              productRiskReference: reducerState.riskAttributes.find(
                (x) => x.code === "PAL"
              )?.instanceId,
              price: 0.0,
              discount: 0.0,
              tax: 0.0,
              total: 0.0,
            })),
          ];

    try {
      setIsLoading(true);
      const res = await dispatch(
        CreatePolicy({
          policies: [
            {
              startDate: null,
              endDate: null,
              PolicyStatus: "Quoted",
              currencyCode: "KES",
              members: [
                {
                  firstName: riskAttributes
                    .find((x) => x.code === RISKS.PLA)
                    ?.attributes?.find((y) => y.name === "Name")?.value,
                  lastName: riskAttributes
                    .find((x) => x.code === RISKS.PLA)
                    ?.attributes?.find((y) => y.name === "Last Name")?.value,
                  birthDate: riskAttributes
                    .find((x) => x.code === RISKS.PLA)
                    ?.attributes?.find((y) => y.name === "Date of Birth")
                    ?.value,
                  mobilePhone: riskAttributes
                    .find((x) => x.code === RISKS.PLA)
                    ?.attributes?.find((y) => y.name === "Mobile Number")
                    ?.value,
                  nationalityId: riskAttributes
                    .find((x) => x.code === RISKS.PLA)
                    ?.attributes?.find((y) => y.name === "ID Number")?.value,
                  KRApin: riskAttributes
                    .find((x) => x.code === RISKS.PLA)
                    ?.attributes?.find((y) => y.name === "KRA Pin")?.value,
                  eMail: riskAttributes
                    .find((x) => x.code === RISKS.PLA)
                    ?.attributes?.find((y) => y.name === "E-Mail Address")
                    ?.value,
                  postalCode: riskAttributes
                    .find((x) => x.code === RISKS.PLA)
                    ?.attributes?.find((y) => y.name === "P.O. Box")?.value,
                  memberType: 1,
                  relationship: 0,
                },
              ],
              beneficiaries: [
                ...beneficiaries.map((x) => ({
                  firstName: x.firstName,
                  lastName: x.lastName,
                  eMail: x.email,
                  mobilePhoneCode: x?.["mobilePhoneCode"],
                  mobilePhone: x.number,
                  split: x.percentage / 100,
                })),
              ],
              attributes: [],
              risks: policyRisks,
              bankAccounts: null,
              productOptionReference:
                customAttributes?.coverOption === "Just Me"
                  ? individualAttribute?.length &&
                    individualAttribute[0].productOptionReference
                  : familyAttribute?.length &&
                    familyAttribute[0].productOptionReference,
              status: 15,
            },
          ],
          networkId:
            process.env.REACT_APP_ENV_TYPE === "DEV"
              ? "fff443a4-bc51-712a-de07-51d47fa2efba"
             : process.env.REACT_APP_ENV_TYPE === "PROD"
              ? "a22e63b1-3d06-1054-ec5f-6a670b64ad5a"
              : agentOnBoarding?.network?.instanceId, // NetworkId from agentOnboarding
          companyId: agentOnBoarding?.company?.code,
          agentId: agentOnBoarding?.agent?.code,
        })
      );
      setIsLoading(true);
      //trackUserEvents
      trackUserEvents("ke_complete_your_details_next_cta", {
        firstName: riskAttributes
          .find((x) => x.code === RISKS.PLA)
          ?.attributes?.find((y) => y.name === "Name")?.value,
        lastName: riskAttributes
          .find((x) => x.code === RISKS.PLA)
          ?.attributes?.find((y) => y.name === "Last Name")?.value,
        birthDate: riskAttributes
          .find((x) => x.code === RISKS.PLA)
          ?.attributes?.find((y) => y.name === "Date of Birth")?.value,
        mobilePhone: riskAttributes
          .find((x) => x.code === RISKS.PLA)
          ?.attributes?.find((y) => y.name === "Mobile Number")?.value,
        nationalityId: riskAttributes
          .find((x) => x.code === RISKS.PLA)
          ?.attributes?.find((y) => y.name === "ID Number")?.value,
        KRApin: riskAttributes
          .find((x) => x.code === RISKS.PLA)
          ?.attributes?.find((y) => y.name === "KRA Pin")?.value,
        eMail: riskAttributes
          .find((x) => x.code === RISKS.PLA)
          ?.attributes?.find((y) => y.name === "E-Mail Address")?.value,
        postalCode: riskAttributes
          .find((x) => x.code === RISKS.PLA)
          ?.attributes?.find((y) => y.name === "P.O. Box")?.value,
        memberType: 1,
        relationship: 0,
      });
      handleNext();
    } catch (err) {
      console.log(err);
    }
  };

   const handleBackTrack = () => {
     //trackUserEvents
     trackUserEvents("ke_complete_your_details_back_cta", {});
     handleBack();
   };

  const beneficiariesState = useSelector(
    (state) => state?.products?.beneficiaries
  );

  useEffect(() => {
    setBeneficiaries(beneficiariesState);
  }, []);

  const [beneficiaries, setBeneficiaries] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      number: "",
      percentage: "",
      mobilePhoneCode: "+254",
    },
  ]);

  const handleChangeBene = (evt, index, name) => {
    var arr = [...beneficiaries];
    if (name) {
      arr[index] = { ...arr[index], [name]: evt };
    } else {
      arr[index] = { ...arr[index], [evt.target.name]: evt.target.value };
    }
    setBeneficiaries(arr);
    dispatch(
      updateCustomAttributes({
        type: "BENEFICIARIES",
        payload: arr,
      })
    );
  };

  const handleAdd = () => {
    if (beneficiaries?.length > 9) return;
    setBeneficiaries([
      ...beneficiaries,
      {
        firstName: "",
        lastName: "",
        email: "",
        number: "",
        mobilePhoneCode: "+254",
        percentage: "",
      },
    ]);
  };

  const handleDelete = (i) => {
    if (beneficiaries?.length <= 1) return;
    var arr = [...beneficiaries];

    arr.splice(i, 1); // 2nd parameter means remove one item only

    setBeneficiaries(arr);
    dispatch(
      updateCustomAttributes({
        type: "BENEFICIARIES",
        payload: arr,
      })
    );
  };

  function range(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }

  const [isTyped, setIsTyped] = useState(false);

  useEffect(() => {
    dispatch(updateAccordion(expand));
  }, [expand]);

  return (
    <div className="mt-4">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">Last Expense Funeral Cover</h3>
            <h5 className="primary-color f-700">Complete your details</h5>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Last Expense Funeral Cover</p>
          <h5 className="primary-color f-700">Complete your details</h5>
        </div>
      )}
      <div className="d-flex justify-content-center align-items-center flex-column mt-5">
        <Info>
          <p className="primary-color">
            Please complete the details below for the policy holder and
            beneficiaries, click “Next” by scrolling to the bottom of the page
            to proceed to payment
          </p>
        </Info>
        <div
          style={{ width: width > 770 ? "460px" : "90%", marginTop: "20px" }}
        >
          <h5 className="primary-color f-700 text-left">
            Please enter your personal details here:
          </h5>
        </div>
        <div
          style={{ width: width > 770 ? "460px" : "90%", marginTop: "20px" }}
        >
          <Accordion
            defaultExpanded={expand.policy}
            onChange={() => setExpand({ ...expand, policy: !expand.policy })}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="d-flex flex-row">
                <div
                  style={{
                    borderRadius: 99,
                    marginRight: 10,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="primary-color f-700">1</span>
                </div>
                <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                  Add policy holder details here
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0 mt-4">
              <div
                style={{
                  border: "1px solid #00000030",
                  padding: 20,
                  borderRadius: 5,
                }}
              >
                {riskAttributes
                  .find((x) => x.code === "PLA")
                  ?.attributes.map((y) => renderComponents(y))}
              </div>
            </AccordionDetails>
          </Accordion>
          {errors && errors["policy"] && (
            <span className="errors">{errors["policy"][0]}</span>
          )}
          <div style={{ height: "20px" }} />
          {/* {protectionRequirement?.value === 1 && ( */}
          {customAttributes?.coverOption === "Family" && (
            <>
              <Accordion
                disabled={
                  spouseCoverAttribute?.value === 1 ||
                  productionRequirement?.value === 0
                }
                defaultExpanded={
                  spouseCoverAttribute?.value === 1 ? false : expand.spouse
                }
                onChange={() =>
                  setExpand({ ...expand, spouse: !expand.spouse })
                }
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex flex-row">
                    <div
                      style={{
                        borderRadius: 99,
                        marginRight: 10,
                        backgroundColor: "white",
                        width: 30,
                        height: 30,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span className="primary-color f-700">2</span>
                    </div>
                    <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                      Add spouse details here
                    </p>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="p-0 mt-4">
                  <div
                    style={{
                      border: "1px solid #00000030",
                      padding: 20,
                      borderRadius: 5,
                    }}
                  >
                    {riskAttributes
                      .find((x) => x.code === "SLA")
                      ?.attributes.map((y) => renderComponents(y, "spouse"))}
                  </div>
                </AccordionDetails>
              </Accordion>
              {errors && errors["spouse"] && (
                <span className="errors">{errors["spouse"][0]}</span>
              )}
              <div style={{ height: "20px" }} />
              <Accordion
                disabled={
                  childCoverAttribute?.value === 1 ||
                  productionRequirement?.value === 0
                }
                defaultExpanded={
                  childCoverAttribute?.value === 1 ? false : expand.child
                }
                onChange={() => setExpand({ ...expand, child: !expand.child })}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex flex-row">
                    <div
                      style={{
                        borderRadius: 99,
                        marginRight: 10,
                        backgroundColor: "white",
                        width: 30,
                        height: 30,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span className="primary-color f-700">3</span>
                    </div>
                    <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                      Add child details here
                    </p>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="p-0 mt-4">
                  {getRows(numberOfChildrens?.value).map((x, i) => (
                    <div
                      style={{
                        border: "1px solid #00000030",
                        marginTop: 20,
                        padding: 20,
                        borderRadius: 5,
                      }}
                    >
                      <p className="primary-color f-700">Child #{i + 1}</p>
                      {riskAttributes
                        .find((x) => x.code === "CLA")
                        ?.attributes.map((y) =>
                          renderComponents(y, "child", i)
                        )}
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
              {errors && errors["child"] && (
                <span className="errors">{errors["child"][0]}</span>
              )}
              <div style={{ height: "20px" }} />
              <Accordion
                disabled={
                  parentCoverAttribute?.value === 1 ||
                  productionRequirement?.value === 0
                }
                defaultExpanded={
                  parentCoverAttribute?.value === 1 ? false : expand.parent
                }
                onChange={() =>
                  setExpand({ ...expand, parent: !expand.parent })
                }
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <div className="d-flex flex-row">
                    <div
                      style={{
                        borderRadius: 99,
                        marginRight: 10,
                        backgroundColor: "white",
                        width: 30,
                        height: 30,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span className="primary-color f-700">4</span>
                    </div>
                    <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                      Add parent holder details here
                    </p>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="p-0 mt-4">
                  {getRows(totalParents()).map((x, i) => (
                    <div
                      style={{
                        border: "1px solid #00000030",
                        marginTop: 20,
                        padding: 20,
                        borderRadius: 5,
                      }}
                    >
                      <p className="primary-color f-700">Parent #{i + 1}</p>
                      {riskAttributes
                        .find((x) => x.code === "PAL")
                        ?.attributes.map((y) =>
                          renderComponents(y, "parent", i)
                        )}
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
              {errors && errors["parent"] && (
                <span className="errors">{errors["parent"][0]}</span>
              )}
              <div style={{ height: "20px" }} />
            </>
          )}
          <Accordion
            defaultExpanded={expand.beneficiaries}
            onChange={() =>
              setExpand({ ...expand, beneficiaries: !expand.beneficiaries })
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="pane41a-content"
              id="panel4a-header"
            >
              <div className="d-flex flex-row">
                <div
                  style={{
                    borderRadius: 99,
                    marginRight: 10,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="primary-color f-700">
                    {customAttributes?.coverOption === "Just Me" ? "2" : "5"}
                  </span>
                </div>
                <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                  Add beneficiaries details here
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0 mt-4">
              {beneficiaries.map((item, i) => (
                <div
                  style={{
                    border: "1px solid #00000030",
                    marginTop: 20,
                    padding: 20,
                    borderRadius: 5,
                  }}
                >
                  <p className="primary-color f-700">Beneficiary #{i + 1}</p>
                  <TextInput
                    id={`First Name ${i + 1}`}
                    label={"First name"}
                    name={"firstName"}
                    value={item.firstName}
                    maxLength={30}
                    onChange={(e) => handleChangeBene(e, i)}
                    required={true}
                    errors={errors[`First Name ${i + 1}`]}
                  />
                  <TextInput
                    id={`Last Name ${i + 1}`}
                    label={"Last name"}
                    name={"lastName"}
                    value={item.lastName}
                    maxLength={30}
                    errors={errors[`Last Name ${i + 1}`]}
                    onChange={(e) => handleChangeBene(e, i)}
                    required={true}
                  />
                  <TextInput
                    id={`Email ${i + 1}`}
                    label={"Email address"}
                    name={"email"}
                    value={item.email}
                    errors={errors[`Email ${i + 1}`]}
                    onChange={(e) => handleChangeBene(e, i)}
                    required={true}
                  />
                  <div className="row">
                    {width > 1024 ? (
                      <>
                        <div className="col-4">
                          <CountryInput
                            id={`mobilePhoneCode ${i + 1}`}
                            label={"Country code"}
                            name={"mobilePhoneCode"}
                            errors={errors[`mobilePhoneCode ${i + 1}`]}
                            value={item.mobilePhoneCode}
                            placeholder={"+254"}
                            onChange={(e) =>
                              handleChangeBene(e, i, "mobilePhoneCode")
                            }
                            required={true}
                            country={true}
                          />
                        </div>
                        <div className="col-8">
                          <NumberInput
                            id={`Phone Number ${i + 1}`}
                            label={"Phone number"}
                            name={"number"}
                            placeholder={"123456789"}
                            value={item.number}
                            errors={errors[`Phone Number ${i + 1}`]}
                            onChange={(e) => handleChangeBene(e, i)}
                            required={true}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-12">
                          <CountryInput
                            id={`mobilePhoneCode ${i + 1}`}
                            label={"Country code"}
                            name={"mobilePhoneCode"}
                            errors={errors[`mobilePhoneCode ${i + 1}`]}
                            value={item.mobilePhoneCode}
                            placeholder={"+255"}
                            onChange={(e) =>
                              handleChangeBene(e, i, "mobilePhoneCode")
                            }
                            required={true}
                            country={true}
                          />
                        </div>
                        <div className="col-12">
                          <NumberInput
                            id={`Phone Number ${i + 1}`}
                            label={"Phone number"}
                            name={"number"}
                            placeholder={"123456789"}
                            value={item.number}
                            errors={errors[`Phone Number ${i + 1}`]}
                            onChange={(e) => handleChangeBene(e, i)}
                            required={true}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="form-group mt-3">
                    <label className="form-label">Percentage of benefit*</label>
                    <ReactSelect
                      id={`Percentage ${i + 1}`}
                      placeholder="0"
                      defaultValue={[
                        ...percentageArr.map((x) => ({
                          label: `${x.name}`,
                          value: x.value,
                        })),
                        ...range(0, 100).map((x, i) => ({
                          label: `${x}%`,
                          value: x,
                        })),
                      ].find((z) => z.value === item?.percentage)}
                      value={[
                        ...percentageArr.map((x) => ({
                          label: `${x.name}`,
                          value: x.value,
                        })),
                        ...range(0, 100).map((x, i) => ({
                          label: `${x}%`,
                          value: x,
                        })),
                      ].find((z) => z.value === item?.percentage)}
                      options={
                        isTyped
                          ? range(0, 100).map((x, i) => ({
                              label: `${x}%`,
                              value: x,
                            }))
                          : percentageArr.map((x) => ({
                              label: `${x.name}`,
                              value: x.value,
                            }))
                      }
                      onInputChange={(e) => {
                        if (e > 0) {
                          setIsTyped(true);
                        } else {
                          setIsTyped(false);
                        }
                      }}
                      onChange={(e) => {
                        handleChangeBene(
                          { target: { name: "percentage", value: e.value } },
                          i
                        );
                      }}
                      isSearchable={true}
                    />
                  </div>
                  {(
                    errors[`Percentage ${i + 1}`] ||
                    errors[`beneficiariesSplit`]
                  )?.length && (
                    <span
                      className="text-danger errors"
                      style={{ marginLeft: 0 }}
                    >
                      {errors[`Percentage ${i + 1}`] ||
                        (errors[`beneficiariesSplit`] &&
                          errors[`Percentage ${i + 1}`]) ||
                        errors[`beneficiariesSplit`][0]}
                    </span>
                  )}

                  <div className="d-flex justify-content-end w-100 mt-4">
                    <button
                      className="delete-button"
                      style={{
                        opacity:
                          beneficiaries?.length === 1 && i === 0 ? 0.5 : 1,
                      }}
                      disabled={beneficiaries?.length === 1 && i === 0}
                      onClick={() => handleDelete(i)}
                    >
                      Delete
                    </button>
                    {i === beneficiaries.length - 1 && (
                      <button className="add-button" onClick={handleAdd}>
                        Add Beneficiaries
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
          {errors && errors["beneficiaries"] && (
            <span className="errors">{errors["beneficiaries"][0]}</span>
          )}
        </div>
      </div>

      <div style={{ height: "50px" }} />

      <div className={style.step_1_price_summary}>
        <div className={style.step_1_price_summary_sub}>
          <h5 className="text-white f-700">Total annual premium </h5>
          <h5 className="text-white f-700">
            KSh{" "}
            {premiumAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ??
              0}
          </h5>
        </div>
      </div>

      <NavigationButtons
        back={handleBackTrack}
        isBackDisabled={isLoading}
        loading={isLoading}
        next={() => {
          if (isLoading) {
            return;
          }
          handleSubmit();
        }}
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        ></div>
      )}
    </div>
  );
};

const percentageArr = [
  {
    name: "10%",
    value: 10,
  },
  {
    name: "20%",
    value: 20,
  },
  {
    name: "30%",
    value: 30,
  },
  {
    name: "40%",
    value: 40,
  },
  {
    name: "50%",
    value: 50,
  },
  {
    name: "60%",
    value: 60,
  },
  {
    name: "70%",
    value: 70,
  },
  {
    name: "80%",
    value: 80,
  },
  {
    name: "90%",
    value: 90,
  },
  {
    name: "100%",
    value: 100,
  },
];

export default Step2;
