import React ,{useEffect} from 'react';
import {useSelector, useDispatch } from "react-redux";
import style from '../../components/components.module.css';
import { Col, Row } from 'react-bootstrap';
import useWindowDimensions from "../../utils/useWindowDimension";
import { Divider } from '@mui/material';
import classNames from "classnames";
import BodyButton from "../../components/Buttons/BodyButton";
import { approvePolicy, rejectPolicy } from "../../store/actions/products";


const StillCovered = (props) => { 
    const { width } = useWindowDimensions();
    document.body.style = 'background: #3C409610;';
    const dispatch = useDispatch();
    const policy = useSelector((state) => state?.prevState?.policy);

    useEffect(async() => {
        dispatch(
            rejectPolicy({
              externalReference: "LETSHEGO_SALE",
              comment: "Rejected from Letshego website",
              policyReference: policy?.policies?.[0]?.policyReference,
            })
          );
      }, []);   

    return (   
        <div className={classNames("container", style.boxBorder)}>
        <div className="mt-4 h-100">
            {width > 770 ? (
                <>
                    <div className="d-flex justify-content-center align-items-center flex-column">
                        <h3 className="primary-color f-700">Last Expense Funeral Cover</h3>
                        <h5 className="primary-color f-700">Policy Application</h5>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            height: "1px",
                            backgroundColor: "#00000020",
                        }}
                    ></div>
                </>
            ) : (
                <div
                    className={classNames(
                        "d-flex justify-content-start align-items-start flex-column",
                        style.headerContainer
                    )}
                >
                    <p className="text-white f-500 m-0 ">Last Expense Funeral Cover</p>
                    <h5 className="primary-color f-700">Policy Application</h5>
                </div>
            )}
        <div className='d-flex justify-content-center flex-column align-items-center mt-5 mb-5'>
                <div className={style.summary_container}>
                    <Row className="justify-content-center">
                        <Col lg={12}>
                            <h3 className="primary-color f-700 text-center">Welcome to the Letshego Family!</h3>
                            <p className="primary-color f-700 text-center">You are covered</p>
                        </Col>
                    </Row>

                    <Divider />


                    <Row className="justify-content-start mt-3">
                        <p className="primary-color f-700 text-center">On completing your payment, you are covered. Should you not receive your policy document via
                        email, please contact us at support@sanlaminsurance.com quoting the following reference:</p>
                    </Row>

                    <Row className="justify-content-between mt-4">
                        <Col>
                            <p className="primary-color f-700 text-left">Policy reference:</p>
                        </Col>
                        <Col>
                            <p className="primary-color f-500 text-right w-100">{policy?.policies?.[0]?.policyReference}</p>
                        </Col>
                    </Row>

                    <Row className="justify-content-between mt-4">
                        <Col>
                            <p className="primary-color f-700 text-left">Policy sale reference:</p>
                        </Col>
                        <Col>
                            <p className="primary-color f-500 text-right w-100">{policy?.policySaleReference}</p>
                        </Col>
                    </Row>

                    <Row className="justify-content-start mt-3">
                        <p className="primary-color f-700 text-center"> Thanks for choosing us.</p>
                    </Row>
                        
                </div>
            </div>
        </div>
        <div style={{textAlign: 'center', marginBottom :"2rem"}}>
        <BodyButton
                          color="white"
                          bg={width > 770 ? "#FBD105" : "#FBD105"}
                          name={"Go To Home"}
                          size={16}
                          weight={700}
                          marginT={0}
                          marginL='auto'
                          marginR='auto'
                          width={width > 770 ? "20%" : "100%"}
                          align={width > 770 ? "left" : "center"}
                          black={width > 430 ? false : true}
                          borderTRR={15}
                          borderBLR={15}
                          borderBRR={15}
                          borderTLR={15}
                          br={100}
                          isHeader={true}
                          rounded={true}
                          link="/"
                        />
                        </div>
    </div>  
    );
}

export default StillCovered;
