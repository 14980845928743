import React, { useEffect, useState } from "react";
import { useDispatch, useSelector} from "react-redux";
import style from "../../Forms/Form.module.css";
import { Col, Row } from "react-bootstrap";
import useWindowDimensions from "../../../utils/useWindowDimension";
import { Divider } from "@mui/material";
import classNames from "classnames";
import BodyButton from "../../../components/Buttons/BodyButton";
import { useLocation, useNavigate } from "react-router";
import {
  getPaymentStatus,
  rejectPolicy,
  approvePolicy,
} from "../../../store/actions/products";
import Summary from "../../../components/Forms/Summary";
import { RISKS } from "../../../utils/constants";
import { sanlam } from "../../../assets/images/index";
import { getAgentOnboarding, login } from "../../../store/actions/auth";
import Loader from "../../../components/Loader";

const Errorpage = (props) => {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();

  document.body.style = "background: #3C409610;";
  const riskAttributes = useSelector(
    (state) => state?.prevState?.riskAttributes
  );
  const premiumAmount = useSelector((state) => state?.prevState?.premium);
  const policy = useSelector((state) => state?.prevState?.policy);

  const parseQuery = (queryString) => {
    var query = {};
    var pairs = (
      queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  };
 

  const [splitResultCode1, setSplitResultCode1] = useState([]);
  const [loader, setLoader] = useState(true);

  //-------getPaymentStatus calling in every 15sec---------///
  useEffect(async () => {
    await dispatch(login());
    //----GetPaymentstatus call-----///
    const fetchPaymentStatus = async () => {
      const res = await dispatch(
        getPaymentStatus(
          parseQuery(location.search)?.referenceNumber,
          parseQuery(location.search)?.saleNumber
        )
      );
      const code = res?.genericCode;
      var splitCode = code.split(".");
      setSplitResultCode1(splitCode);
      setLoader(false);
    };
    // Call the function initially when the component mounts
    fetchPaymentStatus();
    // Set up an interval to call the function every 15 seconds
    const intervalId = setInterval(fetchPaymentStatus, 15000);
    // Clear the interval when the component unmounts to avoid memory leaks
    return () => clearInterval(intervalId);
  }, []);

  const resultCode = parseQuery(location.search)?.result;
  const splitResultCode = resultCode.split(".");

  return splitResultCode1.length && !loader ? (
    <div className="container form-container mt-lg-5 mb-5 p-0">
      <div className="d-flex justify-content-center flex-column">
        {width > 770 ? (
          <>
            {/* <------- HEADER --------> */}
            <h3 className={style.header}>
              Instant Funeral Cover in a few clicks
            </h3>
          </>
        ) : null}

        <div className={classNames("container", style.boxBorder)}>
          <div className="mt-4 h-100">
            {width > 770 ? (
              <>
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <h3 className="primary-color f-700">
                    Last Expense Funeral Cover
                  </h3>
                  <h5 className="primary-color f-700">Policy Application</h5>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#00000020",
                  }}
                ></div>
              </>
            ) : (
              <div
                className={classNames(
                  "d-flex justify-content-start align-items-start flex-column",
                  style.headerContainer
                )}
              >
                <p className="text-white f-500 m-0 ">
                  Last Expense Funeral Cover
                </p>
                <h5 className="primary-color f-700">Policy Application</h5>
              </div>
            )}
            <div className="d-flex justify-content-center flex-column align-items-center mt-5 mb-5">
              <div style={{ maxWidth: "460px", width: "100%" }}>
                <p
                  className="primary-color f-700 text-left w-100"
                  style={{ marginLeft: width > 770 ? "0px" : "20px" }}
                >
                  Your reference & Payment Detailssss
                </p>
              </div>
              <Summary
                title="Last Expense Funeral Cover"
                isFail={
                  splitResultCode1[0] !== "100" && splitResultCode1[0] !== "200"
                    ? true
                    : false
                }
                isPass={splitResultCode1[0] === "200" ? true : false}
                isPending={splitResultCode1[0] === "100" ? true : false}
                referenceNumber={parseQuery(location.search)?.referenceNumber}
                policyHolder={parseQuery(location.search)?.applicant}
                policyHolderSurname={parseQuery(location.search)?.lastName}
                premiumAmount={parseFloat(parseQuery(location.search)?.premium)
                  ?.toFixed(2)
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                coverAmount={parseQuery(location.search)?.coverAmount}
              />
              <div
                style={{ maxWidth: "460px", width: "100%" }}
                className="d-flex justify-content-center flex-column"
              >
                <img src={sanlam} style={{ height: "30px" }} />
                <p style={{ fontSize: 13, textAlign: "center", marginTop: 5 }}>
                  Underwritten by Sanlam Life Insurance Limited.
                  {/* Underwritten by Sanlam Insurance Company Limited (Reg. No.
                  IRA/01/027/01), a Licensed Life Insurer and an authorised
                  Financial Services Provider */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default Errorpage;
