// default imports for react project
import React, { useState } from "react";

// import default styles from react-bootstrap package
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// Import images from assets folder

//   import custom component
import BodyButton from "../Buttons/BodyButton";
import { box, sanlam } from "../../assets/images";
import { useNavigate, useLocation } from "react-router";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  getProductRisks,
  getProductRisksAttributes,
  getProductTypeDetails,
  updateCustomAttributes,
} from "../../store/actions/products";
import { getAgentOnboarding, login } from "../../store/actions/auth";
import { calculator } from "../../components/calculator";
import { radioChecked, radioUnchecked } from "../../assets/images";
import style from "../../screens/Forms/Form.module.css";
import { parseQuery, setParam, trackUserEvents } from "../../utils/utils";

// React functional component starts here..
function Card2({ width }) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const agentCode = useSelector((state) => state.auth.agentCode);
  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);
  const currentToken = useSelector((state) => state.auth.currentUserToken);
  const customAttributes = useSelector(
    (state) => state.products.customAttributes
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isCounter, setIsCounter] = useState(false);
  let timer = null;
  const countDown =
    useSelector((state) => state?.products?.countDown?.count) ?? 0;
  const [coverOption, setCoverOption] = useState();
  const [errors, setErrors] = useState({});

  
  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);


  useEffect(() => {
    if (location.pathname !== "/payment-success") {
      setParam(history, currentToken);
    }
  }, [currentToken]);

  const handleSubmit = async () => {
    try {
      if (!coverOption) {
        return setErrors({ coverOption: ["Cover Option is required"] });
      }
      await dispatch(
        updateCustomAttributes({
          type: "CUSTOM_ATTRIBUTES",
          payload: {
            ...customAttributes,
            coverOption,
          },
        })
      );
      setIsLoading(true);
      setIsCounter(true);
      if (Object.keys(agentOnBoarding || {}).length === 0) {
        await dispatch(getAgentOnboarding(agentCode));
      }
      const riskRes = await dispatch(getProductRisks());
      await dispatch(getProductTypeDetails());
      for (var i = 0; i <= riskRes.risks?.length - 1; i++) {
        await dispatch(getProductRisksAttributes(riskRes.risks[i]?.instanceId));
        if (riskRes.risks?.length - 1 === i) {
          calculator(dispatch, coverOption).then(() => {
            history("/forms");
            setIsLoading(false);
          });
        }
      }
      //trackUserEvents
      trackUserEvents("ke_insurnace_get_a_quote_cta", {});
      setIsCounter(false);
      return;
    } catch (err) {
      console.log(err);
    }
  };


  const renderCoverOptions = () => {
    return (
      <div style={{ width: "90%" }}>
        <div
          className={
            coverOption === "Just Me" ? style.optionsActive1 : style.options1
          }
          onClick={() => {
            setCoverOption("Just Me");
          }}
        >
          <div>
            <img
              src={
                coverOption === "Just Me" ? radioChecked : radioUnchecked
              }
            />
          </div>

          <p className="primary-color f-700">Just Me</p>
        </div>

        <div
          className={
            coverOption === "Family" ? style.optionsActive1 : style.options1
          }
          onClick={() => {
            setCoverOption("Family");
          }}
        >
          <div>
            <img
              src={coverOption === "Family" ? radioChecked : radioUnchecked}
            />
          </div>

          <p className="primary-color f-700">Family</p>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Col lg xs={12}>
        <Row className="m-0 pb-5 card__homepage">
          <div
            style={{
              backgroundColor: "#E5E5F5",
              padding: width < 430 ? "0px" : "",
            }}
          >
            <Row className="m-4">
              <Col lg={10}>
                <h3 style={{ fontSize: "26pt", color: "#2F2E80" }}>
                  It's never been easier to get the insurance cover.{" "}
                </h3>
                <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>
                  if something was to happen to you, you can make sure your
                  family is protected
                </h6>
              </Col>
              <Col lg={2}>
                <Row>
                  <div
                    className={`d-flex ${
                      width > 770
                        ? "justify-content-start"
                        : "justify-content-center"
                    }`}
                  >
                    <img
                      src={sanlam}
                      style={{
                        marginLeft: 36.2,
                        width: "95px",
                        marginTop: "21px",
                      }}
                      alt="sanlam"
                    />
                  </div>
                </Row>
                <Row>
                  <h6
                    style={{
                      color: "#56616C",
                      fontSize: "8pt",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "8px",
                      marginTop: "1px",
                    }}
                  >
                    Policy underwritten by
                  </h6>
                </Row>
              </Col>
            </Row>
            <Row>
              <hr
                style={{
                  color: "white",
                  height: 2,
                  width: "97%",
                  margin: "auto",
                }}
              />
            </Row>

            <Row className="secondary-container p-3 ">
              <Row className="secondary-container p-3 ">
                <Col lg={7}>
                  <Row>
                    <Col lg={3} style={{ textAlign: "right" }}>
                      <img
                        src={box}
                        style={{
                          width: "60%",
                        }}
                        alt="box"
                      />
                    </Col>
                    <Col lg={9}>
                      <h4
                        style={{
                          fontSize: "20px",
                          color: "#2F2E80",
                          fontWeight: "bold",
                        }}
                      >
                        Last Expense Funeral Cover
                      </h4>
                      <span
                        style={{
                          color: "#2F2E80",
                          fontWeight: "bold",
                          marginTop: "8px",
                        }}
                      >
                        Last Expense:
                      </span>
                      <ul>
                        {data2.map((d) => (
                          <li style={{ color: "#2F2E80", marginTop: "8px" }}>
                            <span>{d}</span>
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                </Col>

                <Col
                  lg={5}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div
                    className="whiteoverlay_card pl-5 pr-5 pt-4 pb-4 d-flex align-items-center flex-column"
                    style={{ height: "100%" }}
                  >
                    <Row style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                      <h4
                        style={{
                          fontSize: "20px",
                          color: "white",
                          fontWeight: "bold",
                          textAlign: "center",
                          paddingLeft: "0px",
                          paddingRight: "0px",
                        }}
                      >
                        Last Expense Funeral Cover
                      </h4>
                    </Row>
                    <div className="homepage_dob_container">
                      {renderCoverOptions()}
                      {errors?.coverOption ? (
                        <span className="errors" style={{ color: "#fff" }}>
                          {errors?.coverOption?.[0]}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                    <Row>
                      <h4
                        style={{
                          fontSize: "11pt",
                          textAlign: "center",
                          color: "white",
                          fontWeight: "bold",
                          marginTop: "8px",
                        }}
                      >
                        4 Cover options to choose from
                      </h4>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                      {data.map((d) => (
                        <h4 style={{ color: "white", fontSize: "11pt" }}>
                          <span style={{ fontWeight: "bold", color: "white" }}>
                            {d.split("-")[0]} -
                          </span>
                          <span style={{ color: "white" }}>
                            {d.split("-")[1]}
                          </span>
                        </h4>
                      ))}
                    </Row>
                    <Row>
                      <div>
                        {data4.map((d) => (
                          <h4
                            style={{
                              color: "white",
                              marginTop: "20px",
                              textAlign: "center",
                              fontSize: "11pt",
                            }}
                          >
                            <span>•</span>
                            <span style={{ marginLeft: "3px" }}>{d}</span>
                          </h4>
                        ))}
                      </div>
                    </Row>
                    <Row>
                      <BodyButton
                        color="white"
                        bg={width < 770 ? "#FBD105" : "#FBD105"}
                        name={width < 770 ? "Buy Cover Now" : "Buy Cover Now"}
                        // onClick={() => history("/forms")}
                        onClick={handleSubmit}
                        size={16}
                        weight={700}
                        marginT={"25px"}
                        rounded={true}
                        borderTRR={25}
                        borderBLR={25}
                        borderBRR={25}
                        borderTLR={25}
                        marginR={width < 770 ? 0 : 50}
                        width={width < 770 ? "auto" : "100%"}
                        align={width < 770 ? "left" : "center"}
                        black={width < 430 ? false : true}
                        br={100}
                        isHeader={true}
                      />
                    </Row>
                    <Row
                      className="justify-content-center"
                      style={{
                        fontSize: "11pt",
                        color: "white",
                        textAlign: "center",
                        marginTop: "8px",
                        fontWeight: "bold",
                        paddingLeft: "11px",
                        marginBottom: "25px",
                      }}
                    >
                      From: KES 350pa / Annually
                    </Row>
                    <hr
                      style={{
                        color: "white",
                        height: 1,
                        width: "97%",
                        margin: "auto",
                      }}
                    />
                    <Row
                      className="justify-content-center"
                      style={{
                        fontSize: "8pt",
                        color: "white",
                        textAlign: "center",
                        marginBottom: "5px",
                        padding: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      By opting to buy insurance, Letshego will share the
                      required information with the insurer to facilitate your
                      policy purchase.
                    </Row>
                    <Row>
                      <div
                        className={`d-flex ${
                          width > 770
                            ? "justify-content-start"
                            : "justify-content-center"
                        }`}
                      >
                        <img
                          src={sanlam}
                          style={{
                            marginTop: "5px",
                            width: "95px",
                          }}
                          alt="sanlam"
                        />
                      </div>
                    </Row>
                    <Row>
                      <h6
                        style={{
                          color: "white",
                          fontSize: "8pt",
                          fontWeight: "bold",
                          textAlign: "center",
                          marginBottom: "8px",
                          marginTop: "1px",
                        }}
                      >
                        Policy underwritten by
                      </h6>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Row>
          </div>
        </Row>
      </Col>
      {isLoading && (
        <div
          style={{
            height: "99vh",
            width: "100%",
            zIndex: 100,
            backgroundColor: "#00000050",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "0px",
          }}
        >
          <div style={{ height: 100, width: 100 }}>
            <CircularProgressbar
              styles={buildStyles({
                textColor: "#fbcf2c",
                pathColor: "#fbcf2c",
              })}
              value={countDown}
              text={`${countDown}%`}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Card2;

const data = [
  "Option 1 - KES 50,000",
  "Option 2 - KES 75,000",
  "Option 3 - KES 100,000",
  "Option 4 - KES 200,000",
];

const data2 = [
  "A solution offered to Individuals/Groups/Organizations /Employers/Saccos. The cover provides for the payment of the sum assured to the dependents of a deceased member to take care of the funeral expenses.",
  "Accidental deaths are covered immediately for all family members. Illness related deaths attract a waiting period of three (3) months for the principal member, spouse, children, and parents.",
];

const data3 = [
  "Option 1: Cover of KSh 50,000",
  "Option 2: Cover of KSh 75,000",
  "Option 3: Cover of KSh 100,000",
  "Option 4: Cover of KSh 200,000",
];

const data4 = [
  "Accidental deaths are covered immediately for all family members.",
  "Illness related deaths attract a waiting period of three (3) months for the principal member, spouse, children, and parents.",
  "Claims are processed within 48 hours",
];
