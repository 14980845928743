import React, { useState } from "react";

// import default style component from react-bootstrap package
import { Col, Container, Row } from "react-bootstrap";

// Import image from assets folder
import { main, Fullmain,sanlam } from "../../assets/images";

//   import custom component
import BodyButton from "../../components/Buttons/BodyButton";
import Card2 from "../../components/Card/Card2";

// import util to get current window dimensions
import useWindowDimensions from "../../utils/useWindowDimension";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  getProductRisks,
  getProductRisksAttributes,
  getProductTypeDetails,
  updateCustomAttributes,
} from "../../store/actions/products";
import {
  getAgentOnboarding,
  login,
} from "../../store/actions/auth";
import { ATTRIBUTES, RISKS } from "../../utils/constants";
import { calculator } from "../../components/calculator";
import Loader from "../../components/Loader";
import { clearState } from "../../store/actions/products";
import { radioChecked, radioUnchecked } from "../../assets/images";
import style from "../../screens/Forms/Form.module.css";
import { parseQuery, setParam, trackUserEvents } from "../../utils/utils";


// React functional component starts here..
function Homepage({ isHomeLoading }) {
  // scroll window to top of page on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const history = useNavigate();
  const currentToken = useSelector((state) => state.auth.currentUserToken);

  const agentCode = useSelector((state) => state.auth.agentCode);

  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);

  const [isLoading, setIsLoading] = useState(false);

  const [homeLoading, setHomeLoading] = useState(false);

  const state = useSelector((state) => state.products.riskAttributes);
  const types = useSelector((state) => state.products.types);
  const customAttributes = useSelector((state) => state.products.customAttributes) 
  const [coverOption, setCoverOption] = useState();
  const [errors, setErrors] = useState({});


  // Risk from the Product
  const risks = useSelector((state) =>
    state.products?.risks?.risks?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  // Risk attributes from the Product i.e. BUD, CEU, ICU, etc.
  const risksAttributes = useSelector((state) =>
    state.products?.riskAttributes?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );
  const countDown = useSelector((state) => state?.products?.countDown?.count) ?? 0;

  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    if (location.pathname !== "/payment-success") {
      setParam(history, currentToken);
    }
  }, [currentToken]);

  useEffect(() => {
    dispatch({type: "CLEAR_STATE"})
  }, [dispatch]);
  
  
  const [isCounter, setIsCounter] = useState(false);
  let timer = null;


  const handleSubmit = async () => {
    try{
      if (!coverOption) {
        return setErrors({ coverOption: ["Cover Option is required"] });
      }
      await dispatch(
        updateCustomAttributes({
          type: "CUSTOM_ATTRIBUTES",
          payload: {
            ...customAttributes,
            coverOption,
          },
        })
      );
      setIsLoading(true);
      setIsCounter(true);
      if (Object.keys(agentOnBoarding || {}).length === 0) {
        await dispatch(getAgentOnboarding(agentCode));
      }
      const riskRes = await dispatch(getProductRisks());
      await dispatch(getProductTypeDetails());
      riskRes?.risks?.forEach(async (risk, index) => {
        await dispatch(getProductRisksAttributes(risk.instanceId));
        if (riskRes?.risks?.length - 1 === index) {
          calculator(dispatch, coverOption).then(() => {
            history("/forms");
            setIsLoading(false);
          });
        }
      });
      //trackUserEvents
      trackUserEvents("ke_insurnace_get_a_quote_cta", {});
      setIsCounter(false);
      return;
    }catch(err){
      console.log(err);
    }
  }



  const renderCoverOptions = () => {
    return (
      <div style={{width: '90%'}}>
        <div
          className={
            coverOption === "Just Me" ? style.optionsActive1 : style.options1
          }
          onClick={() => {
            setCoverOption("Just Me");
          }}
        >
          <div>
            <img
              src={
                coverOption === "Just Me" ? radioChecked : radioUnchecked
              }
            />
          </div>

          <p className="primary-color f-700">Just Me</p>
        </div>
        
        <div
          className={
            coverOption === "Family" ? style.optionsActive1 : style.options1
          }
          onClick={() => {
            setCoverOption("Family");
          }}
        >
          <div>
            <img
              src={coverOption === "Family" ? radioChecked : radioUnchecked}
            />
          </div>

          <p className="primary-color f-700">Family</p>
        </div>
      </div>
    );
  };



  // return !isLoading ? (
    return (
    <div>
      {width > 1024 ? (
        <Container>
          <div className="homepage-banner-pattern">
            <Row className="py-3 ">
              <Col lg={5} xs={12} className="text-center main__img_container">
                <img className="main__img" src={Fullmain} alt="main" />
              </Col>
              <Col lg={7} xs={12} className="homepage__main__text">
                <Row>
                 <p className="homepage1__heading1 app__header__one m-0 justify-content-center" style={{textAlign:"center"}}> Family Protection </p>
                </Row>
                <Row>
                  <p className="homepage1__heading2 m-0 pt-1 justify-content-center" style={{textAlign:"center"}}>Funeral cover to take care of the funeral expenses</p>
                </Row>
                <Row className="py-4 m-0 justify-content-center">
                  <Col xs={6} md={4} className="p-0">
                    {width > 700 && (
                        <BodyButton
                          color="white"
                          bg={width > 770 ? "#FBD105" : "#FBD105"}
                          name={width > 770 ? "Buy Cover Now" : "Buy Cover Now"}
                          onClick={handleSubmit}
                          size={16}
                          weight={700}
                          marginT={0}
                          marginL={width > 770 ? "auto" : 0}
                          marginR={width > 770 ? "auto" : 0}
                          width={width > 770 ? "180px" : "100%"}
                          align={width > 770 ? "left" : "center"}
                          black={width > 430 ? false : true}
                          borderTRR={25}
                          borderBLR={25}
                          borderBRR={25}
                          borderTLR={25}
                          br={100}
                          isHeader={true}
                          rounded={true}
                        />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Row className="mt-0">
            <Col sm={12}>
              <Card2 width={width} />
            </Col>
          </Row>
        </Container>
      ) : (
        <div className="homepage-banner-pattern">
          <Row
            className="py-3 justify-content-center"
            style={{ margin: "15px" }}
          >
            <Col lg={7} xs={12} className="homepage__main__text">
              <Row className="homepage1__heading1 app__header__one m-0 justify-content-center">
                Family Protection
              </Row>
              <Row
                className="homepage1__heading2 m-0 pt-1 justify-content-center"
                style={{ marginLeft: "16px" }}
              >
                Funeral cover to take care of the funeral expenses
              </Row>
            </Col>
            <Col
              lg={5}
              xs={12}
              className="text-center main__img_container"
              style={{ marginBottom: "30px" }}
            >
              <img className="main__img" src={Fullmain} alt="main" />
            </Col>
            <Row
              className="justify-content-center"
              style={{ backgroundColor: "#59599A", borderRadius: "5px" }}
            >
              <Col lg={7} xs={12} className="homepage__main__text">
                <Row className="py-4 m-0 justify-content-center">
                  <Col
                    xs={6}
                    md={4}
                    className="p-0"
                    style={{ position: "absolute", marginTop: "-45px" }}
                  >
                    {width < 700 && (
                        <BodyButton
                          color="#2F2E80"
                          bg={width < 770 ? "#FBD105" : "#2F2E80"}
                          name={width < 770 ? "Buy Cover Now" : "Buy Cover Now"}
                          onClick={handleSubmit}
                          size={16}
                          rounded={true}
                          weight={700}
                          marginT={0}
                          marginR={width < 770 ? 0 : 50}
                          borderTRR={25}
                          borderBLR={25}
                          borderBRR={25}
                          borderTLR={25}
                          width={width < 770 ? "auto" : "100%"}
                          align={width < 770 ? "left" : "center"}
                          black={width < 430 ? false : true}
                          br={100}
                          isHeader={true}
                        />
                    )}
                  </Col>
                </Row>
              </Col>

              <Row style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <h4
                  style={{
                    fontSize: "20px",
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "center",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  Last Expense Funeral Cover
                </h4>
              </Row>

              <div className="homepage_dob_container">
                  {renderCoverOptions()}
                  {errors?.coverOption ? (
                                  <span
                                    className="errors"
                                    style={{ color: "#fff" }}
                                  >
                                    {errors?.coverOption?.[0]}
                                  </span>
                                ) : (
                                  <></>
                                )}
                </div>
              <Row>
                <h6
                  style={{
                    fontSize: "10pt",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  4 Cover options to choose from
                </h6>
              </Row>
              <Row style={{textAlign: "center"}}>
                    {data.map((d) => (
                      <h4 style={{ color: "white", fontSize:"11pt"}}>
                        <span style={{ fontWeight: "bold", color: "white"}}>
                          {d.split("-")[0]} -
                        </span>
                        <span style={{ color: "white" }}>
                          {d.split("-")[1]}
                        </span>
                      </h4>
                    ))}
                  </Row>
                  <Row>
                    <div>
                        {data2.map((d) => (
                          <h4 style={{ color: "white", marginTop:"20px",textAlign:"center" ,fontSize:"12pt"}}>
                          <span>•</span><span style={{marginLeft:"3px"}}>{d}</span>
                          </h4>
                        ))}
                      </div>
                  </Row>
                  <Row className="py-4 m-0 justify-content-center">
              <Col xs={6} md={4} className="p-0">
                {width < 700 && (
                    <BodyButton
                      color="#2F2E80"
                      bg={width < 770 ? "#FBD105" : "#5b727a"}
                      name={width < 770 ? "Buy Cover Now" : "Buy Cover Now"}
                      onClick={handleSubmit}
                      size={16}
                      weight={700}
                      marginT={0}
                      rounded={true}
                      borderTRR={25}
                      borderBLR={25}
                      borderBRR={25}
                      borderTLR={25}
                      marginR={width < 770 ? 0 : 50}
                      width={width < 770 ? "auto" : "100%"}
                      align={width < 770 ? "left" : "center"}
                      black={width < 430 ? false : true}
                      br={100}
                      isHeader={true}
                    />
                )}
              </Col>
            </Row>
            <Row
                    className="justify-content-center"
                    style={{
                      fontSize: "11pt",
                      color: "white",
                      textAlign: "center",
                      marginTop: "8px",
                      fontWeight: "bold",
                      paddingLeft:"11px",
                      marginBottom: "25px",
                    }}
                  >
                    From: KES 350pa / Annually
                </Row>
                <hr
                  style={{
                    color: "white",
                    height: 1,
                    width: "97%",
                    margin: "auto",
                  }}
                />
            <Row
                className="justify-content-center"
                style={{
                  fontSize: "8pt",
                  color: "white",
                  textAlign: "center",
                  marginBottom: "5px",
                  padding:"14px",
                  fontWeight:"bold"
                }}
              >
                By opting to buy insurance, Letshego will share the required
                information with the insurer to facilitate your policy purchase.
              </Row>
                <Row>
                <div
                  className={`d-flex ${
                    width > 770
                      ? "justify-content-start"
                      : "justify-content-center"
                  }`}
                >
                  <img
                    src={sanlam}
                    style={{
                      width: "95px",
                      marginTop:"5px"
                    }} 
                    alt="sanlam"
                  />
                </div>
            </Row>
            <Row>
              <h6
                  style={{
                    color: "white",
                    fontSize: "8pt",
                    fontWeight: "bold",
                    textAlign: "center", 
                    marginBottom: "10px",
                    marginTop:"1px"
                  }}
                >Policy underwritten by</h6>
                </Row>
            </Row>
            
            
          </Row>
        </div>
      )}
      {isLoading && (
        <div
          style={{
            height: "99vh",
            width: "100%",
            zIndex: 100,
            backgroundColor: "#00000050",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "0px",
          }}
        >
          <div style={{height: 100, width: 100}}>
            <CircularProgressbar styles={buildStyles({textColor: '#fbcf2c', pathColor:'#fbcf2c'})} value={countDown} text={`${countDown}%`} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Homepage;

const data = [
  "Option 1 - KES 50,000",
  "Option 2 - KES 75,000",
  "Option 3 - KES 100,000",
  "Option 4 - KES 200,000",
];

const data2 = [
  "Accidental deaths are covered immediately for all family members.",
  "Illness related deaths attract a waiting period of three (3) months for the principal member, spouse, children, and parents.",
  "Claims are processed within 48 hours",
];
