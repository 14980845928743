import {
  BANK_BRANCH_GUID,
  ENV,
  ENV_TYPE,
  MIDDLEWARE_URL,
  OCP_APIM_SUBSCRIPTION_KEY,
  PAYMENT_SUBSCRIPTION_KEY,CONFIGURATION_KEY,
  PRODUCT_INSTANCE_ID,
} from "./api-constants";

// <-------- Constants ------->

// Define the ENV TYPE in ENV_VAR
const ENV_VAR = ENV_TYPE[process.env.REACT_APP_ENV_TYPE];
const CONSTANTS = {
  BASEURL: ENV[ENV_VAR],
  ENV: ENV_VAR,
  OCP_APIM_SUBSCRIPTION_KEY: OCP_APIM_SUBSCRIPTION_KEY[ENV_VAR],
  PAYMENT_SUBSCRIPTION_KEY: PAYMENT_SUBSCRIPTION_KEY[ENV_VAR],
  PRODUCT_INSTANCE_ID: PRODUCT_INSTANCE_ID[ENV_VAR],
  BANK_BRANCH_GUID: BANK_BRANCH_GUID[ENV_VAR],
  MIDDLEWARE_URL: MIDDLEWARE_URL[ENV_VAR],
  CONFIGURATION_KEY: CONFIGURATION_KEY[ENV_VAR],
  CURRENCY_CODE: "277",
};
export default CONSTANTS;

export const PRODUCTION_CONSTANTS = {
  REFERER_BASE_URL_PROD: 'https://policy-cache-itp2-prod-za.azurewebsites.net',
  COMPANY_DETAILS: 'https://organisation-itp2-prod-za.azurewebsites.net',
  PRODUCT_RISKS: 'https://product-itp2-prod-za.azurewebsites.net',
  BANK_LOOKUPS: 'https://maintaindata-itp2-prod-za.azurewebsites.net',
  POLICY: 'https://policy-itp2-prod-za.azurewebsites.net'
}


// <-------- Common Messages ------->
export const COMMONMESSAGES = {};


export const ATTRIBUTES = {
  COVER_LEVEL: 'Cover Level',
  CHILD_COVER: 'Child Cover',
  SPOUSE_COVER: "Spouse Cover",
  PROTECTION_REQUIREMENT: 'Protection Requirement',
  NUMBER_OF_CHILDREN: "Number of Children",
  PARENTAL_COVER: 'Parental Cover',
  NUMBER_OF_PARENTS: 'Number of Parents',
  PARENT_55: 'Parents < 55 yrs',
  PARENT_65: 'Parents 55 - 65 yrs',
  PARENT_80: 'Parents 65 - 80 yrs'
}

export const RISKS = {
  QUQ: 'QUQ',
  PLA: 'PLA',
  PAL: 'PAL',
  CLA: 'CLA',
  SLA: 'SLA'
}

export const CONFIG = {
  development: "http://localhost:3001",
  uat: "https://app.uat.letshego.com",
  production: "https://app.uat.letshego.com",
  // production: "https://app.production.com",
};