// default imports for react project
import classNames from "classnames";
import React from "react";

// default import for routing in react
import { useNavigate } from "react-router";

import Style from "./Button.module.css";

// React functional component starts here..
function BodyButton({
  name,
  color,
  bg,
  size,
  marginL,
  weight,
  width,
  marginT,
  black,
  rounded = false,
  align,
  float,
  link,
  borderTRR,
  borderBRR,
  borderTLR,
  borderBLR,
  isHomePage,
  isHeader = false,
  br,
  marginR,
  onClick
}) {
  // initialized history using react hook used to navigate
  const history = useNavigate();

  // function to redirect page
  const handleClick = () => {
    history(link);
  };

  // return jsx to display on screen
  return (
    <div
      style={{
        background: `${bg}`,
        color: `${color}`,
        fontSize: size,
        width:  width === -1 ? null : (width ? width : "65%"),
        marginLeft: marginL,
        marginRight: marginR,
        fontWeight: weight,
        cursor: "pointer",
        marginTop: marginT,
        borderRadius: rounded ? '10px' : '0px',
        textAlign: "center",
        borderTopRightRadius:  borderTRR ? borderTRR :"",
        borderBottomRightRadius: borderBRR ? borderBRR : "",
        borderTopLeftRadius: borderTLR ? borderTLR : "",
        borderBottomLeftRadius: borderBLR ? borderBLR : "",
        float: float ? float : "none"
        
      }}
      className={classNames("px-2 py-2 d-flex",isHomePage === undefined ? Style.body__btn__hover : Style.home_body__btn__hover, isHeader === true ? Style.header_home_body__btn__hover : "")}
      // onClick={handleClick}
      onClick={onClick}
    >
      <div className="w-100">{name}</div>
    </div>
  );
}

export default BodyButton;
