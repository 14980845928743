/////----Payment with getProductPayment,generateCheckout----/////////
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ConfirmationBox, Info } from "../../../components";
import CONSTANTS from "../../../utils/constants";

import NavigationButtons from "../../../components/NavigationButtons";
import { storeState } from "../../../store/actions/prevState";
import {
  getProductPayment,
  generateCheckout,
  updateCustomAttributes,
  submitPolicy,
  checkPaymentStatus,
} from "../../../store/actions/products";
import useWindowDimensions from "../../../utils/useWindowDimension";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap"; 
import style from "../Form.module.css";
import Loader from "../../../components/Loader";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import { trackUserEvents } from "../../../utils/utils";

const Step3 = ({ handleNext, handleBack }) => {
  const history = useNavigate();
  const { width } = useWindowDimensions();

  const premiumAmount = useSelector((state) => state?.products?.premium);

  const dispatch = useDispatch();
  const riskAttributes = useSelector((state) => state.products?.riskAttributes?.find((x) => x.code === "PLA")?.attributes);
  const terms = useSelector((state) => state?.products?.terms);
  const policyResponse = useSelector((state) => state.products.policy);
  const mobileNumber = useSelector((state) => state?.products);
  const mobilePaymentResponse = useSelector((state) => state.products.mobilePaymentResponse);
  const paymentResponse1 = useSelector(
    (state) => state.products.PaymentResponse
  );
  const [progress, setProgress] = useState(0);

  var plaRA = mobileNumber?.riskAttributes?.filter(
    (ra) => ra.code === "PLA"
  )[0];
  var mobileNumberValue = plaRA?.attributes?.filter(
    (att) => att.description === "Mobile number"
  )[0];

  const [isLoading, setIsLoading] = useState(false);
  const [checkPayment, setCheckPayment] = useState(false);
  const [state, setState] = useState({
    checked: false,
    count: 0,
  });
const [isFormLoading, setFormIsLoading] = useState(false);
const [isForm, setIsForm] = useState(false);
const [generateForm, setGenerateForm] = useState("");
const protectionIndividual = useSelector((state) => state?.products?.protectionIndividual);
  const protectionFamily = useSelector((state) => state?.products?.protectionFamily);
  const customAttributes = useSelector((state) => state?.products?.customAttributes); 
const riskAttributesQuq = useSelector((state) => state?.products?.riskAttributes?.find((x) => x?.code === "QUQ")?.attributes);

const getAttributesQuq = (value) => { return riskAttributesQuq?.find((x) => x.name === value)};

 const individualCoverAmount = `${(
            (protectionIndividual &&
              protectionIndividual[0]?.valuations?.values.find((x) =>
                x.name.includes(
                  `${
                    getAttributesQuq(ATTRIBUTES.COVER_LEVEL)?.value
                  } - Main Member`
                )
              )?.value))?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`


const familyCoverAmount = `${(
            (protectionFamily &&
              protectionFamily[0]?.valuations?.values.find((x) =>
                x.name.includes(
                  `${
                    getAttributesQuq(ATTRIBUTES.COVER_LEVEL)?.value
                  } - Main Member`
                )
              )?.value))?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`


const handleSubmit = async () => {
  try {
    setIsLoading(true);
    const poliyholderName = riskAttributes?.filter((x) => x.name === "Name")[0]
      ?.value;
    const poliyLastName = riskAttributes?.filter(
      (x) => x.name === "Last Name"
    )[0]?.value;
    const mainMemberEmail = riskAttributes?.filter(
      (x) => x.name === "E-Mail Address"
    )[0]?.value;

    const coverAmount =
      customAttributes?.coverOption === "Just Me"
        ? individualCoverAmount
        : familyCoverAmount;
    //----kenya payment methods calls start-----////
    const product_Payment = await dispatch(getProductPayment());
    if (product_Payment) {
      const data = {
        paymentInstanceId:
          process.env.REACT_APP_ENV_TYPE === "PROD"
            ? product_Payment.providers[1].configurationKey
            : product_Payment.productPayment[1].configurationKey, //crct -M-pesa
        email: mainMemberEmail,
        companyId: product_Payment.companyInstanceId,
        policyReferenceId:
          policyResponse && policyResponse?.policies[0]?.policyReference,
        policySaleReference: policyResponse?.policySaleReference,
        amount: premiumAmount,
        currency: "KES",
        checkoutType: 1,
        shopperSuccessUrl: `${window.location.origin}/payment-success?test="test"&referenceNumber=${policyResponse?.policies[0]?.policyReference}&applicant=${poliyholderName}&premium=${premiumAmount}&saleNumber=${policyResponse?.policySaleReference}&lastName=${poliyLastName}&coverAmount=${coverAmount}`,
        shopperFailureUrl: `${window.location.origin}/payment-failure?test="test"&referenceNumber=${policyResponse?.policies[0]?.policyReference}&applicant=${poliyholderName}&premium=${premiumAmount}&saleNumber=${policyResponse?.policySaleReference}&lastName=${poliyLastName}&coverAmount=${coverAmount}`,
      };
      const generate_Checkout = await dispatch(generateCheckout(data));
      if (generate_Checkout?.data?.data?.form) {
        dispatch(
          storeState({ generateCheckout: generate_Checkout?.data?.data })
        );
        setIsForm(true);
        setGenerateForm(generate_Checkout?.data?.data?.form);
      }
    }
    //---kenya payment methods calls ends -----////

    //trackUserEvents
    trackUserEvents("ke_declarations_submit_cta", {});
    setIsLoading(false);
    dispatch(storeState());
  }catch (err) {
      console.log(err);
      toast.error("Error occured");
      setIsLoading(false);
    }
}

  const handleBackTrack = () => {
    //trackUserEvents
    trackUserEvents("ke_declarations_payments_back_cta", {});
    handleBack();
  };

const paymentScript = useSelector(
  (state) => state?.prevState?.generateCheckout?.script
);

useEffect(() => {
  if (paymentScript) {
    let myScript = paymentScript;
    myScript = myScript.replace("<script>", "");
    myScript = myScript.replace("</script>", "");
    const script = document.createElement("script");

    script.innerHTML = myScript;

    document.body.appendChild(script);
  }
}, [paymentScript]);



function clickHandler(e) {
  const el = e.target.closest("div > form > table > tbody > tr > td > input");
  const er = document.getElementsByTagName("input")[0]?.value;
  const pattern = "254[0-9]{9}|0";
  const value = el?.value;
  if (el && e.currentTarget.contains(el) && er?.length) {
    // Check if the pattern matches
    const patternRegex = new RegExp(pattern);
    if (patternRegex.test(er)) {
      setFormIsLoading(true);
    } else {
      // Handle pattern mismatch by preventing the default form submission
      e.preventDefault();
      toast.error("Input pattern does not match");
    }
  }
}

  return (
    <>
      <div className="mt-4">
        {width > 770 ? (
          <>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <h3 className="primary-color f-700">
                Last Expense Funeral Cover
              </h3>
              <h5 className="primary-color f-700">
                {isForm ? "Mpesa Mobile Payment" : "Declarations"}
              </h5>
            </div>
            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "#00000020",
              }}
            ></div>
          </>
        ) : (
          <div
            className={classNames(
              "d-flex justify-content-start align-items-start flex-column",
              style.headerContainer
            )}
          >
            <p className="text-white f-500 m-0 ">Last Expense Funeral Cover</p>
            <h5 className="primary-color f-700">Policy Application</h5>
          </div>
        )}

        <Container style={{ maxWidth: "460px" }}>
          <div className="d-flex justify-content-center align-items-start flex-column mt-5">
            {isForm ? (
              isForm && (
                <>
                  <Row className={classNames("mt-5")}>
                    <div
                      dangerouslySetInnerHTML={{ __html: generateForm }}
                      onClick={clickHandler}
                    />
                  </Row>
                </>
              )
            ) : (
              <>
                <h5 className="primary-color f-700">You're almost there</h5>
                <Info>
                  <p className="primary-color f-700">
                    Your application will be submitted to Sanlam Life. Once
                    confirmed, we shall issue the policy document.
                  </p>
                </Info>
                <div style={{ height: "20px" }} />
                <ConfirmationBox
                  value={terms}
                  onChange={(e) =>
                    dispatch(
                      updateCustomAttributes({
                        type: "TERMS",
                        payload: e.target.checked,
                      })
                    )
                  }
                >
                  <p className="primary-color">
                    {" "}
                    I confirm that I have read and understood the terms and
                    conditions governing the provision of funeral insurance
                    services, and agree to be bound by them. I accept Letshego
                    seeking any information from previous insurers, who have
                    previously received an application from myself.
                  </p>
                </ConfirmationBox>
                <div style={{ height: "20px" }} />
              </>
            )}
          </div>
        </Container>
        <div style={{ height: "20px" }} />

        <div className={style.step_1_price_summary}>
          <div className={style.step_1_price_summary_sub}>
            <h5 className="text-white f-700">Total annual premium</h5>
            <h5 className="text-white f-700">
              KSh{" "}
              {premiumAmount
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}
            </h5>
          </div>
        </div>

        <NavigationButtons
          back={handleBackTrack}
          isFormDisplayed={isForm ? true : false}
          payment={true}
          loading={isLoading}
          isBackDisabled={isLoading || isFormLoading}
          next={() => {
            if (!terms) {
              return toast.error("Please confirm terms & conditions");
            }
            handleSubmit();
          }}
        />
      </div>

      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Loader /> */}
        </div>
      )}

      {isFormLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Loader />
        </div>
      )}
    </>
  );
};

export default Step3;

