import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Dropdown, NumberInput, TabBar, TwoButtons } from "../../../components";
import NavigationButtons from "../../../components/NavigationButtons";
import useWindowDimensions from "../../../utils/useWindowDimension";
import { useDispatch, useSelector } from "react-redux";
import style from "../Form.module.css";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import Loader from "../../../components/Loader";
import {
  updateCustomAttributes,
  updatedAttributes,
} from "../../../store/actions/products";
import { validate } from "validate.js";
import { useNavigate } from "react-router";
import { trackUserEvents } from "../../../utils/utils";

const Step1 = ({ handleNext, handleBack }) => {
  const [inputs, setInputs] = useState({
    coverOptions: null,
    options: null,
    children: null,
    additionalChildren: null,
    parent: null,
    parentAbove65: 0,
    parentAbove80: 0,
  });

  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const state = useSelector((state) => state.products.riskAttributes);
  const history = useNavigate();

  const protectionRequirement = state
    .find((x) => x.code === RISKS.QUQ)
    ?.attributes?.find((y) => y.name === ATTRIBUTES.PROTECTION_REQUIREMENT);

  const protectionIndividual = useSelector(
    (state) => state.products?.protectionIndividual
  );

  const protectionFamily = useSelector(
    (state) => state.products?.protectionFamily
  );

  const parentCover = useSelector((state) =>
    state?.products?.riskAttributes
      ?.find((x) => x.code === RISKS.QUQ)
      ?.attributes?.find((y) => y.name === ATTRIBUTES.PARENTAL_COVER)
  );
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    var total = protectionFamily?.length + protectionIndividual?.length;
    if (total === 4) {
      setLoader(false);
    }
  }, [protectionFamily, protectionIndividual]);

  const riskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );
  const customAttributes = useSelector((state) => state.products.customAttributes); 

  const getAttributes = (value) => {
    return riskAttributes?.find((x) => x.name === value);
  };

  const handleChange = (evt, instanceId) => {
    var arr = [...riskAttributes];
    var index = riskAttributes.findIndex((x) => x.instanceId === instanceId);
    arr[index] = { ...arr[index], value: evt };
    dispatch(updatedAttributes(arr, "QUQ"));
  };
  const getProtectionIndex = (val) => {
    switch (val) {
      case "Option 1":
        return 0;
      case "Option 2":
        return 1;
      case "Option 3":
        return 2;
      case "Option 4":
        return 3;
    }
  };

  const parseString = (string, name) => {
    const arr = string.split(",");
    let obj = {};
    arr.forEach((a) => {
      const val = a.split(":");
      obj[val[0]] = val[1];
    });
    return obj;
  };

  const isLoading = useSelector((state) => state?.products?.isLoading);

  const individualDetails = [
    {
      title: "Premium",
      content: [
        {
          title: "Annual",
          value: `KSh ${
            customAttributes?.coverOption === "Just Me"
              ? protectionIndividual[
                  getProtectionIndex(
                    getAttributes(ATTRIBUTES.COVER_LEVEL)?.value
                  )
                ]?.risks
                ? protectionIndividual[
                    getProtectionIndex(
                      getAttributes(ATTRIBUTES.COVER_LEVEL)?.value
                    )
                  ]?.risks[0]?.rating?.total
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
              : customAttributes?.coverOption === "Family"
              ? protectionFamily[
                  getProtectionIndex(
                    getAttributes(ATTRIBUTES.COVER_LEVEL)?.value
                  )
                ]?.risks
                ? protectionFamily[
                    getProtectionIndex(
                      getAttributes(ATTRIBUTES.COVER_LEVEL)?.value
                    )
                  ]?.risks[0]?.rating?.total
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
              : null
          }`,
        },
      ],
    },
    {
      title: "Level of Cover",
      content: [
        {
          title: "Main member",
          value: `KSh ${(
            (protectionIndividual &&
              protectionIndividual[0]?.valuations?.values.find((x) =>
                x.name.includes(
                  `${
                    getAttributes(ATTRIBUTES.COVER_LEVEL)?.value
                  } - Main Member`
                )
              )?.value) ??
            "Loading.."
          )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        },
      ],
    },
  ];

  const familyDetails = [
    {
      title: "Premium",
      content: [
        {
          title: "Annual",
          value: `KSh ${
            customAttributes?.coverOption === "Just Me"
              ? protectionIndividual[
                  getProtectionIndex(
                    getAttributes(ATTRIBUTES.COVER_LEVEL)?.value
                  )
                ]?.risks
                ? protectionIndividual[
                    getProtectionIndex(
                      getAttributes(ATTRIBUTES.COVER_LEVEL)?.value
                    )
                  ]?.risks[0]?.rating?.total
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
              : customAttributes?.coverOption === "Family"
              ? protectionFamily[
                  getProtectionIndex(
                    getAttributes(ATTRIBUTES.COVER_LEVEL)?.value
                  )
                ]?.risks
                ? protectionFamily[
                    getProtectionIndex(
                      getAttributes(ATTRIBUTES.COVER_LEVEL)?.value
                    )
                  ]?.risks[0]?.rating?.total
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
              : null
          }`,
        },
      ],
    },
    {
      title: "Level of Cover",
      content: [
        {
          title: "Main member",
          value: `KSh ${(
            (protectionFamily &&
              protectionFamily[0]?.valuations?.values.find((x) =>
                x.name.includes(
                  `${
                    getAttributes(ATTRIBUTES.COVER_LEVEL)?.value
                  } - Main Member`
                )
              )?.value) ??
            "Loading.."
          )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        },
        {
          title: "Spouse",
          value: `KSh ${(
            (protectionFamily &&
              protectionFamily[0]?.valuations?.values.find((x) =>
                x.name.includes(
                  `${getAttributes(ATTRIBUTES.COVER_LEVEL)?.value} - Spouse`
                )
              )?.value) ??
            "Loading.."
          )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        },
        {
          title: "Children (4 included)",
          value: `KSh ${(
            (protectionFamily &&
              protectionFamily[0]?.valuations?.values.find((x) =>
                x.name.includes(
                  `${getAttributes(ATTRIBUTES.COVER_LEVEL)?.value} - Children`
                )
              )?.value) ??
            "Loading.."
          )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        },
        {
          title: "Parent",
          value: `KSh ${(
            (protectionFamily &&
              protectionFamily[0]?.valuations?.values.find((x) =>
                x.name.includes(
                  `${getAttributes(ATTRIBUTES.COVER_LEVEL)?.value} - Parent`
                )
              )?.value) ??
            "Loading.."
          )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        },
      ],
    },
  ];

  const totalParents = () => {
    var total = riskAttributes?.filter(
      (x) =>
        x.name === ATTRIBUTES.PARENT_55 ||
        x.name === ATTRIBUTES.PARENT_65 ||
        x.name === ATTRIBUTES.PARENT_80
    );
    return total?.reduce((a, b) => a + (b?.value ?? 0), 0);
  };

  const getConstraints = () => {
    var obj = {};

    if (!protectionRequirement?.value) {
      obj = {
        ["Cover Level"]: {
          presence: { allowEmpty: false },
        },
      };
      return obj;
    }

    if (customAttributes?.coverOption === "Family") {
      obj = {
        ["Cover Level"]: {
          presence: { allowEmpty: false },
        },
        [getAttributes(ATTRIBUTES.CHILD_COVER)?.name]: {
          presence: { allowEmpty: false },
        },
        [getAttributes(ATTRIBUTES.SPOUSE_COVER)?.name]: {
          presence: { allowEmpty: false },
        },

        [getAttributes(ATTRIBUTES.PARENTAL_COVER)?.name]: {
          presence: { allowEmpty: false },
        },
      };
      if (getAttributes(ATTRIBUTES.CHILD_COVER)?.value === 0) {
        obj = {
          ...obj,
          [getAttributes(ATTRIBUTES.NUMBER_OF_CHILDREN)?.name]: {
            presence: { allowEmpty: false },
            numericality: {
              greaterThan: 0,
            },
          },
        };
      }
      if (getAttributes(ATTRIBUTES.PARENTAL_COVER)?.value === 0) {
        obj = {
          ...obj,
          [getAttributes(ATTRIBUTES.PARENT_55)?.name]: {
            presence: { allowEmpty: false },
          },
          [getAttributes(ATTRIBUTES.PARENT_65)?.name]: {
            presence: { allowEmpty: false },
          },
          [getAttributes(ATTRIBUTES.PARENT_80)?.name]: {
            presence: { allowEmpty: false },
          },
        };
      }
      return obj;
    }
    if (customAttributes?.coverOption === "Just Me") {
      obj = {
        ["Cover Level"]: {
          presence: { allowEmpty: false },
        },
      };
      return obj;
    }
  };

  const [errors, setErrors] = useState({});
  const parentState = useSelector((state) => state.products?.parentAttributes);
  console.log('parenttState--->', parentState)
  const getRows = (rows) => {
    var arr = [];
    for (var i = 0; i < rows; i++) {
      arr.push(i + 1);
    }
    return arr;
  };

  const handleSubmit = () => {
    try {
      if (getAttributes(ATTRIBUTES.PARENTAL_COVER)?.value === 0) {
        if (totalParents() >= 5) {
          return setErrors({
            ...errors,
            totalParent: ["Total parents must be less than 4"],
          });
        } else if (totalParents() <= 0) {
          return setErrors({
            ...errors,
            totalParent: ["Total parents must be greater than 0"],
          });
        }
      }

      const errorss = validate(
        {
          "Cover Level": getAttributes(ATTRIBUTES.COVER_LEVEL)?.value,
          [getAttributes(ATTRIBUTES.CHILD_COVER)?.name]: getAttributes(
            ATTRIBUTES.CHILD_COVER
          )?.value,
          [getAttributes(ATTRIBUTES.SPOUSE_COVER)?.name]: getAttributes(
            ATTRIBUTES.SPOUSE_COVER
          )?.value,
          [getAttributes(ATTRIBUTES.PARENTAL_COVER)?.name]: getAttributes(
            ATTRIBUTES.PARENTAL_COVER
          )?.value,
          [getAttributes(ATTRIBUTES.PARENT_55)?.name]:
            getAttributes(ATTRIBUTES.PARENT_55)?.value ?? 0,
          [getAttributes(ATTRIBUTES.PARENT_65)?.name]:
            getAttributes(ATTRIBUTES.PARENT_65)?.value ?? 0,
          [getAttributes(ATTRIBUTES.PARENT_80)?.name]:
            getAttributes(ATTRIBUTES.PARENT_80)?.value ?? 0,
          [getAttributes(ATTRIBUTES.NUMBER_OF_CHILDREN)?.name]:
            getAttributes(ATTRIBUTES.NUMBER_OF_CHILDREN)?.value ?? 0,
        },
        getConstraints()
      );
      if (errorss || errorss !== undefined) {
        var errorArray = Object.keys(errorss);
        document.getElementById(errorArray[0]).scrollIntoView({
          behavior: "smooth",
        });
        setErrors(errorss);
        return;
      } else {
        setErrors({});
      }
      var arr = [];
      var relationships = state
        ?.find((x) => x.code === "PAL")
        ?.attributes?.find((y) => y.name === "Relationship");
      if (totalParents()) {
        getRows(totalParents()).map((x, i) => {
          arr.push({
            ...parentState[i],
            Relationship: JSON.parse(relationships.settings)?.items[0],
            ParentMobileNumbercode: "+254"
          });
        });
      }
      dispatch(
        updateCustomAttributes({
          type: "PARENT_ATTRIBUTES",
          payload: arr,
        })
      );
      console.log(
        "vall--->",
        "parentalCover-->",
        getAttributes(ATTRIBUTES.PARENTAL_COVER)?.value,
        "parent55-->",
        getAttributes(ATTRIBUTES.PARENT_55)?.value ?? 0,
        "parent65-->",
        getAttributes(ATTRIBUTES.PARENT_65)?.value ?? 0,
        "parent80-->",
        getAttributes(ATTRIBUTES.PARENT_80)?.value ?? 0,
        "NumberOfChild-->",
        getAttributes(ATTRIBUTES.NUMBER_OF_CHILDREN)?.value ?? 0
      );
      //trackUserEvents
      trackUserEvents(
        "ke_insurance_select_your_cover_cta",
        customAttributes?.coverOption === "Family"
          ? {
              "Cover Level": getAttributes(ATTRIBUTES.COVER_LEVEL)?.value,
              "Spouse Cover":
                getAttributes(ATTRIBUTES.SPOUSE_COVER)?.value === 0
                  ? "Yes"
                  : "No",
              "Child Cover":
                getAttributes(ATTRIBUTES.CHILD_COVER)?.value === 0
                  ? "Yes"
                  : "No",
              "Number Of Children":
                getAttributes(ATTRIBUTES.NUMBER_OF_CHILDREN)?.value ?? 0,
              "Parental Cover":
                getAttributes(ATTRIBUTES.PARENTAL_COVER)?.value === 0
                  ? "Yes"
                  : "No",
              "Parents 55": getAttributes(ATTRIBUTES.PARENT_55)?.value ?? 0,
              "Parents 55-65": getAttributes(ATTRIBUTES.PARENT_65)?.value ?? 0,
              "Parents 65-80": getAttributes(ATTRIBUTES.PARENT_80)?.value ?? 0,
            }
          : { "Cover Level": getAttributes(ATTRIBUTES.COVER_LEVEL)?.value }
      );
      handleNext();
    } catch (err) {
      console.log(err);
    }
  };

  const handleBackTrack = () => {
    history("/");
    //trackUserEvents
    trackUserEvents("ke_insurance_select_your_cover_back_cta", {});
  };

  const rates = () => {
    if (customAttributes?.coverOption === "Just Me") {
      var val = protectionIndividual?.length
        ? parseString(protectionIndividual[0]?.risks[0]?.rating?.message)
        : {};
      return val;
    } else if (customAttributes?.coverOption === "Family") {
      const index = getProtectionIndex(
        riskAttributes?.find((x) => x.name === ATTRIBUTES.COVER_LEVEL)?.value
      );
      var val = protectionFamily?.length
        ? parseString(protectionFamily[index]?.risks[0]?.rating?.message)
        : {};
      return val;
    }
  };

  const premiumAmount = () => {
    var total =
      customAttributes?.coverOption === "Just Me"
        ? protectionIndividual[
            getProtectionIndex(getAttributes(ATTRIBUTES.COVER_LEVEL)?.value)
          ]?.risks
          ? protectionIndividual[
              getProtectionIndex(getAttributes(ATTRIBUTES.COVER_LEVEL)?.value)
            ]?.risks[0]?.rating?.total
          : 0
        : customAttributes?.coverOption === "Family"
        ? protectionFamily[
            getProtectionIndex(getAttributes(ATTRIBUTES.COVER_LEVEL)?.value)
          ]?.risks
          ? protectionFamily[
              getProtectionIndex(getAttributes(ATTRIBUTES.COVER_LEVEL)?.value)
            ]?.risks[0]?.rating?.total
          : 0
        : 0;

    if (customAttributes?.coverOption === "Just Me") {
      return total;
    } else if (customAttributes?.coverOption === "Family") {
      var childRate = riskAttributes?.find(
        (x) => x.name === ATTRIBUTES.CHILD_COVER
      )?.value
        ? 0
        : riskAttributes?.find((x) => x.name === ATTRIBUTES.NUMBER_OF_CHILDREN)
            ?.value >= 5
        ? rates()[" ChildRate "] *
            riskAttributes?.find(
              (x) => x.name === ATTRIBUTES.NUMBER_OF_CHILDREN
            )?.value -
          rates()[" ChildRate "] * 4
        : 0;
      var parent55 = riskAttributes?.find(
        (x) => x.name === ATTRIBUTES.PARENTAL_COVER
      )?.value
        ? 0
        : riskAttributes?.find((x) => x.name === ATTRIBUTES.PARENT_55)?.value
        ? riskAttributes?.find((x) => x.name === ATTRIBUTES.PARENT_55)?.value *
          (rates()[" parentRate55 "] ?? 0)
        : 0;
      var parent65 = riskAttributes?.find(
        (x) => x.name === ATTRIBUTES.PARENTAL_COVER
      )?.value
        ? 0
        : riskAttributes?.find((x) => x.name === ATTRIBUTES.PARENT_65)?.value
        ? riskAttributes?.find((x) => x.name === ATTRIBUTES.PARENT_65)?.value *
          (rates()[" parentRate65 "] ?? 0)
        : 0;
      var parent80 = riskAttributes?.find(
        (x) => x.name === ATTRIBUTES.PARENTAL_COVER
      )?.value
        ? 0
        : riskAttributes?.find((x) => x.name === ATTRIBUTES.PARENT_80)?.value
        ? riskAttributes?.find((x) => x.name === ATTRIBUTES.PARENT_80)?.value *
          (rates()[" parentRate80 "] ?? 0)
        : 0;
      var t = +total + +childRate + +parent55 + +parent65 + +parent80;

      return t;
    }
  };

  useEffect(() => {
    dispatch(
      updateCustomAttributes({
        type: "PREMIUM",
        payload: premiumAmount(),
      })
    );
  }, [riskAttributes]);
  return riskAttributes?.length && !loader ? (
    <div className="mt-4 h-100">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">Last Expense Funeral Cover</h3>
            <h2 className="primary-color f-800">Select your cover</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Last Expense Funeral Cover</p>
          <h2 className="primary-color f-800">Select your cover</h2>
        </div>
      )}

      <div className="d-flex justify-content-center align-items-center flex-column mt-5">
        {console.log("CoverLevel-->")}
        {/* <TabBar
          id="Cover Level"
          state={getAttributes(ATTRIBUTES.COVER_LEVEL)?.value}
          onClick={(e) =>
            handleChange(e, getAttributes(ATTRIBUTES.COVER_LEVEL)?.instanceId)
          }
          disabled={
            customAttributes &&
            (customAttributes?.coverOption === "Just Me" ||
              customAttributes?.coverOption === "Family")
              ? true
              : false
          }
          errors={errors["Cover Level"]}
          options={
            getAttributes(ATTRIBUTES.COVER_LEVEL)?.settings
              ? JSON.parse(
                  getAttributes(ATTRIBUTES.COVER_LEVEL)?.settings
                )?.items.map((x, i) => ({
                  label: x,
                  value: x,
                  price: (customAttributes?.coverOption === "Just Me"
                    ? protectionIndividual
                    : protectionFamily)[i]?.risks[0]?.valuations?.values?.find(
                    (z) => z.name.includes(`${x} - Main Member`)
                  )
                    ? (customAttributes?.coverOption === "Just Me"
                        ? protectionIndividual
                        : protectionFamily)[i]?.risks[0]?.valuations?.values
                        ?.find((z) => z.name.includes(`${x} - Main Member`))
                        ?.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : null,
                }))
              : null
          }
          data={
            isLoading
              ? []
              : customAttributes?.coverOption === "Just Me"
              ? individualDetails
              : familyDetails
          }
        /> */}
      
        {/* //---3--// */}
        <TabBar
          id="Cover Level"
          state={getAttributes(ATTRIBUTES.COVER_LEVEL)?.value}
          onClick={(e) =>
            handleChange(e, getAttributes(ATTRIBUTES.COVER_LEVEL)?.instanceId)
          }
          disabled={
            customAttributes &&
            (customAttributes?.coverOption === "Just Me" ||
              customAttributes?.coverOption === "Family")
              ? true
              : false
          }
          errors={errors["Cover Level"]}
          options={
            getAttributes(ATTRIBUTES.COVER_LEVEL)?.settings
              ? JSON.parse(
                  getAttributes(ATTRIBUTES.COVER_LEVEL)?.settings
                )?.items.map((x, i) => {
                  const protectionData =
                    customAttributes?.coverOption === "Just Me"
                      ? protectionIndividual
                      : protectionFamily;
                  console.log("protectionData:", protectionData);
                  let valuation = null;
                  for (const risk of protectionData[i]?.risks) {
                    for (const val of risk.valuations) {
                      valuation = val.values.find((v) =>
                        v.name.includes(`${x} - Main Member`)
                      );
                      if (valuation) break;
                    }
                    if (valuation) break;
                  }
                  console.log("valuation:", valuation);
                  return {
                    label: x,
                    value: x,
                    price: valuation?.value
                      ? valuation.value
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : null,
                  };
                })
              : null
          }
          data={
            isLoading
              ? []
              : customAttributes?.coverOption === "Just Me"
              ? individualDetails
              : familyDetails
          }
        />

        {customAttributes?.coverOption === "Family" &&
        getAttributes(ATTRIBUTES.COVER_LEVEL)?.value ? (
          <>
            <div style={{ height: "20px" }} />
            {getAttributes(ATTRIBUTES.SPOUSE_COVER) && (
              <TwoButtons
                id={getAttributes(ATTRIBUTES.SPOUSE_COVER)?.name}
                half={true}
                title={`${getAttributes(ATTRIBUTES.SPOUSE_COVER)?.description}`}
                state={getAttributes(ATTRIBUTES.SPOUSE_COVER)?.value}
                errors={
                  Object.keys(errors)
                    ? errors[getAttributes(ATTRIBUTES.SPOUSE_COVER)?.name]
                      ? errors[getAttributes(ATTRIBUTES.SPOUSE_COVER)?.name]
                      : [""]
                    : []
                }
                onClick={(e) =>
                  handleChange(
                    e,
                    getAttributes(ATTRIBUTES.SPOUSE_COVER)?.instanceId
                  )
                }
                options={[
                  {
                    name: "Yes",
                    value: 0,
                  },
                  {
                    name: "No",
                    value: 1,
                  },
                ]}
              />
            )}
            <div style={{ height: "20px" }} />
            <TwoButtons
              id={getAttributes(ATTRIBUTES.CHILD_COVER)?.name}
              half={true}
              title={`${getAttributes(ATTRIBUTES.CHILD_COVER)?.description}`}
              state={getAttributes(ATTRIBUTES.CHILD_COVER)?.value}
              errors={
                Object.keys(errors)
                  ? errors[getAttributes(ATTRIBUTES.CHILD_COVER)?.name]
                  : []
              }
              onClick={(e) =>
                handleChange(
                  e,
                  getAttributes(ATTRIBUTES.CHILD_COVER)?.instanceId
                )
              }
              options={[
                {
                  name: "Yes",
                  value: 0,
                },
                {
                  name: "No",
                  value: 1,
                },
              ]}
            />
            {getAttributes(ATTRIBUTES.CHILD_COVER)?.value === 0 ? (
              <div
                className={classNames(style.twoButtonContainer, "p-31")}
                id={getAttributes(ATTRIBUTES.NUMBER_OF_CHILDREN)?.name}
              >
                <p className="primary-color f-700">
                  {getAttributes(ATTRIBUTES.NUMBER_OF_CHILDREN)?.description}
                </p>
                <NumberInput
                  half={true}
                  customWidth={200}
                  hideLabel={true}
                  type="number"
                  id={getAttributes(ATTRIBUTES.NUMBER_OF_CHILDREN)?.name}
                  errors={
                    Object.keys(errors)
                      ? errors[
                          getAttributes(ATTRIBUTES.NUMBER_OF_CHILDREN)?.name
                        ]
                      : []
                  }
                  value={getAttributes(ATTRIBUTES.NUMBER_OF_CHILDREN)?.value}
                  onChange={(value) => {
                    if (value.target.value <= 10 && value.target.value >= 0) {
                      handleChange(
                        parseInt(value.target.value),
                        getAttributes(ATTRIBUTES.NUMBER_OF_CHILDREN)?.instanceId
                      );
                    }
                  }}
                />
              </div>
            ) : null}

            <div style={{ height: "20px" }} />
            <TwoButtons
              id={getAttributes(ATTRIBUTES.PARENTAL_COVER)?.name}
              half={true}
              errors={
                Object.keys(errors)
                  ? errors[getAttributes(ATTRIBUTES.PARENTAL_COVER)?.name]
                  : []
              }
              title={`${getAttributes(ATTRIBUTES.PARENTAL_COVER)?.description}`}
              state={getAttributes(ATTRIBUTES.PARENTAL_COVER)?.value}
              onClick={(e) =>
                handleChange(
                  e,
                  getAttributes(ATTRIBUTES.PARENTAL_COVER)?.instanceId
                )
              }
              options={[
                {
                  name: "Yes",
                  value: 0,
                },
                {
                  name: "No",
                  value: 1,
                },
              ]}
            />

            {/* <---------- Parent 55 Starts----------> */}

            {parentCover?.value === 0 && (
              <>
                {" "}
                <div style={{ height: "20px" }} />
                <div
                  className={classNames(style.twoButtonContainer, "p-3")}
                  id={getAttributes(ATTRIBUTES.PARENT_55)?.name}
                >
                  <p className="primary-color f-700">
                    {getAttributes(ATTRIBUTES.PARENT_55)?.description}
                  </p>
                  <NumberInput
                    half={true}
                    customWidth={200}
                    hideLabel={true}
                    type="number"
                    id={getAttributes(ATTRIBUTES.PARENT_55)?.name}
                    errors={
                      Object.keys(errors)
                        ? errors[getAttributes(ATTRIBUTES.PARENT_55)?.name]
                        : []
                    }
                    value={getAttributes(ATTRIBUTES.PARENT_55)?.value}
                    onChange={(value) => {
                      if (value.target.value >= 0 && value.target.value <= 4) {
                        handleChange(
                          parseInt(value.target.value),
                          getAttributes(ATTRIBUTES.PARENT_55)?.instanceId
                        );
                      }
                    }}
                    title={getAttributes(ATTRIBUTES.PARENT_55)?.description}
                  />
                </div>
                {/* <---------- Parent 55 Ends----------> */}
                {/* <---------- Parent 65 Starts----------> */}
                <div
                  className={classNames(style.twoButtonContainer, "p-3")}
                  id={getAttributes(ATTRIBUTES.PARENT_65)?.name}
                >
                  <p className="primary-color f-700">
                    {getAttributes(ATTRIBUTES.PARENT_65)?.description}
                  </p>
                  <NumberInput
                    half={true}
                    customWidth={200}
                    hideLabel={true}
                    type="number"
                    id={getAttributes(ATTRIBUTES.PARENT_65)?.name}
                    errors={
                      Object.keys(errors)
                        ? errors[getAttributes(ATTRIBUTES.PARENT_65)?.name]
                        : []
                    }
                    outerErrors={
                      Object.keys(errors)
                        ? errors?.totalParent &&
                          errors?.totalParent?.length &&
                          errors?.totalParents
                        : []
                    }
                    value={getAttributes(ATTRIBUTES.PARENT_65)?.value}
                    onChange={(value) => {
                      if (value.target.value >= 0 && value.target.value <= 4) {
                        handleChange(
                          parseInt(value.target.value),
                          getAttributes(ATTRIBUTES.PARENT_65)?.instanceId
                        );
                      }
                    }}
                    title={getAttributes(ATTRIBUTES.PARENT_65)?.description}
                  />
                </div>
                <Dropdown
                  half={true}
                  display="none"
                  id={getAttributes(ATTRIBUTES.PARENT_65)?.name}
                  value={getAttributes(ATTRIBUTES.PARENT_65)?.value}
                  options={dropdownDataParent}
                  errors={
                    Object.keys(errors)
                      ? errors?.[getAttributes(ATTRIBUTES.PARENT_65)?.name]
                      : []
                  }
                  onClick={(value) =>
                    handleChange(
                      value,
                      getAttributes(ATTRIBUTES.PARENT_65)?.instanceId
                    )
                  }
                  title={getAttributes(ATTRIBUTES.PARENT_65)?.description}
                />
                {/* <---------- Parent 65 Ends----------> */}
                {/* <---------- Parent 80 Starts----------> */}
                <div
                  className={classNames(style.twoButtonContainer, "p-3")}
                  id={getAttributes(ATTRIBUTES.PARENT_80)?.name}
                >
                  <p className="primary-color f-700">
                    {getAttributes(ATTRIBUTES.PARENT_80)?.description}
                  </p>
                  <NumberInput
                    half={true}
                    customWidth={200}
                    hideLabel={true}
                    type="number"
                    id={getAttributes(ATTRIBUTES.PARENT_80)?.name}
                    errors={
                      Object.keys(errors)
                        ? errors[getAttributes(ATTRIBUTES.PARENT_80)?.name]
                        : []
                    }
                    value={getAttributes(ATTRIBUTES.PARENT_80)?.value}
                    onChange={(value) => {
                      if (value.target.value >= 0 && value.target.value <= 4) {
                        handleChange(
                          parseInt(value.target.value),
                          getAttributes(ATTRIBUTES.PARENT_80)?.instanceId
                        );
                      }
                    }}
                    title={getAttributes(ATTRIBUTES.PARENT_80)?.description}
                  />
                </div>
                <Dropdown
                  half={true}
                  display="none"
                  id={getAttributes(ATTRIBUTES.PARENT_80)?.name}
                  options={dropdownDataParent}
                  value={getAttributes(ATTRIBUTES.PARENT_80)?.value}
                  errors={
                    Object.keys(errors)
                      ? errors?.[getAttributes(ATTRIBUTES.PARENT_80)?.name]
                      : []
                  }
                  outerErrors={
                    Object.keys(errors)
                      ? errors?.totalParent &&
                        errors?.totalParent?.length &&
                        errors?.totalParent
                      : []
                  }
                  onClick={(value) =>
                    handleChange(
                      value,
                      getAttributes(ATTRIBUTES.PARENT_80)?.instanceId
                    )
                  }
                  title={getAttributes(ATTRIBUTES.PARENT_80)?.description}
                />
              </>
            )}

            {/* <---------- Parent 80 Ends----------> */}
            <div style={{ height: "20px" }} />
          </>
        ) : null}
      </div>

      <div style={{ height: "20px" }} />

      <div className={style.step_1_price_summary}>
        <div className={style.step_1_price_summary_sub}>
          <h5 className="text-white f-700">Total annual premium</h5>
          <h5 className="text-white f-700">
            KSh{" "}
            {premiumAmount()
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}
          </h5>
        </div>
      </div>

      <NavigationButtons back={handleBackTrack} next={handleSubmit} />
    </div>
  ) : (
    <Loader />
  );
};

const dropdownData = [
  {
    name: "0",
    value: 0,
  },
  {
    name: "1",
    value: 1,
  },
  {
    name: "2",
    value: 2,
  },
  {
    name: "3",
    value: 3,
  },
  {
    name: "4",
    value: 4,
  },
  {
    name: "5",
    value: 5,
  },
  {
    name: "6",
    value: 6,
  },
  {
    name: "7",
    value: 7,
  },
  {
    name: "8",
    value: 8,
  },
  {
    name: "9",
    value: 9,
  },
  {
    name: "10",
    value: 10,
  },
];

const dropdownDataParent = [
  {
    name: "0",
    value: 0,
  },
  {
    name: "1",
    value: 1,
  },
  {
    name: "2",
    value: 2,
  },
  {
    name: "3",
    value: 3,
  },
  {
    name: "4",
    value: 4,
  },
];

export default Step1;

