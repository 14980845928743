import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "../Forms/Form.module.css";
import { Col, Row } from "react-bootstrap";
import useWindowDimensions from "../../utils/useWindowDimension";
import { Divider } from "@mui/material";
import classNames from "classnames";
import BodyButton from "../../components/Buttons/BodyButton";
import { useLocation } from "react-router";
import { rejectPolicy, approvePolicy } from "../../store/actions/products";
import Summary from "../../components/Forms/Summary";
import { RISKS } from "../../utils/constants";
import {sanlam} from '../../assets/images/index'; 


const Error = (props) => {
  const { width } = useWindowDimensions();
  document.body.style = "background: #3C409610;";
  const dispatch = useDispatch();
  const riskAttributes = useSelector((state) => state?.prevState?.riskAttributes);
  const premiumAmount = useSelector((state) => state?.prevState?.premium);
  const policy = useSelector((state) => state?.prevState?.policy);
  const location = useLocation();

  useEffect(() => {  
    dispatch(
      rejectPolicy({
        externalReference: "LETSHEGO_SALE",
        comment: "Rejected from Letshego website",
        policyReference: policy?.policies?.[0]?.policyReference,
      })
    );
  }, []);  

  return (
    <div className="container form-container mt-lg-5 mb-5 p-0">
      <div className="d-flex justify-content-center flex-column">
        {width > 770 ? (
          <>
            {/* <------- HEADER --------> */}
            <h3 className={style.header}>
              Instant Funeral Cover in a few clicks
            </h3>
          </>
        ) : null}

        <div className={classNames("container", style.boxBorder)}>
          <div className="mt-4 h-100">
            {width > 770 ? (
              <>
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <h3 className="primary-color f-700">
                    Last Expense Funeral Cover
                  </h3>
                  <h5 className="primary-color f-700">Policy Application</h5>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#00000020",
                  }}
                ></div>
              </>
            ) : (
              <div
                className={classNames(
                  "d-flex justify-content-start align-items-start flex-column",
                  style.headerContainer
                )}
              >
                <p className="text-white f-500 m-0 ">
                  Last Expense Funeral Cover
                </p>
                <h5 className="primary-color f-700">Policy Application</h5>
              </div>
            )}
            <div className="d-flex justify-content-center flex-column align-items-center mt-5 mb-5">
              <div style={{maxWidth: '460px', width: '100%'}}>
                <p className="primary-color f-700 text-left w-100" style={{marginLeft: width > 770 ? '0px' : '20px'}}>Your reference & Payment Details</p>
              </div>
              <Summary
                title="Last Expense Funeral Cover"
                isFail={true}
                referenceNumber={
                  policy?.policies && policy?.policies[0]?.policyReference
                }
                policyHolder={
                  riskAttributes
                    ?.find((x) => x.code === RISKS.PLA)
                    ?.attributes?.find((y) => y.name === "Name")?.value ?? "NA"
                }
                policyHolderSurname={
                  riskAttributes
                    ?.find((x) => x.code === RISKS.PLA)
                    ?.attributes?.find((y) => y.name === "Last Name")?.value ??
                  "NA"
                }
                premiumAmount={premiumAmount
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
              <div style={{maxWidth: '460px' ,width: '100%'}} className="d-flex justify-content-center flex-column">
                            <img src={sanlam} style={{height: '30px'}} />
                            <p style={{fontSize: 13, textAlign: 'center', marginTop: 5}}>
                             Underwritten by Sanlam Life Insurance Limited. 
                            {/* Underwritten by Sanlam Insurance Company Limited (Reg. No. IRA/01/027/01), a Licensed Life Insurer and an authorised Financial Services Provider */}
                            </p>
                          </div>
            </div>
          </div>
        </div>

        {/* <div style={{ textAlign: "center", marginBottom: "2rem" }}>
          <BodyButton
            color="white"
            bg={width > 770 ? "#FBD105" : "#FBD105"}
            name={"Go To Home"}
            size={16}
            weight={700}
            marginT={0}
            marginL="auto"
            marginR="auto"
            width={width > 770 ? "20%" : "100%"}
            align={width > 770 ? "left" : "center"}
            black={width > 430 ? false : true}
            borderTRR={15}
            borderBLR={15}
            borderBRR={15}
            borderTLR={15}
            br={100}
            isHeader={true}
            rounded={true}
            link="/"
          />
        </div> */}
      </div>
    </div>
  );
};

export default Error;
